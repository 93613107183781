import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, TextField, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { StyledLabel } from '../../components/StyledComponents';
import { setIsOpenCardChangeModal } from '../../redux/mainReducer';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';
import visaMaster from '../../assets/visaMaster.png';
import cardChip from '../../assets/cardChip.png';

import { LetsGoBtn } from '../../components/Buttons';
import ApiService from '../../helpers/apiHelper';
import { base } from '../../redux/thunk';
import './DeleteModal.css';
import { decryptData, encryptData, generateKeys } from '../../helpers/cryptoHelper';
import { Spinner } from '../../components/Spinner';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 60px 50px 60px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function CardChageModal({ isOpenModal, getPremiumSubscriptionThunk, userData }) {
  const dispatch = useDispatch();
  const api = new ApiService('auth/change-token-card');
  const apiHash = new ApiService('auth/hash');
  const [cardNum, setCardNum] = useState('');
  const [cardNumError, setCardNumError] = useState(null);
  const [expDate, setExpDate] = useState('');
  const [expDateError, setExpDateError] = useState(null);
  const [cvv, setCvv] = useState('');
  const [cvvError, setCvvError] = useState(null);
  const [isLoadingBtn, setIsLoadingBtn] = useState(true);
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  const keysGenerator = () => {
    const keys = generateKeys();
    if (keys) {
      setPublicKey(keys?.publicKey);
      setPrivateKey(keys?.privateKey);
      setIsLoadingBtn(false);
    }
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        keysGenerator();
      }, [200]);
    }
  }, []);
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenCardChangeModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenCardChangeModal(false));
            }}
          />

          <Grid
            container
            spacing={1}
            sx={{
              position: 'relative',
              maxWidth: '608px',
              ':after': {
                content: '""',
                width: '370px',
                height: '230px',
                position: 'absolute',
                background:
                  'linear-gradient(225deg, rgb(0 0 0 / 46%) 0%, rgba(0, 0, 0, 0.28335084033613445) 53%, rgba(255, 255, 255, 1) 100%)',
                // backgroundColor: 'white',
                zIndex: '0',
                left: 'calc(50% - 185px)',
                top: '130px',
                borderRadius: '20px',
                border: '1px solid black',
                boxShadow: '0px 0px 10px -3px black'
              },
              ':before': {
                content: '""',
                width: '370px',
                height: '50px',
                position: 'absolute',
                background:
                  'linear-gradient(322deg, rgb(0 0 0) 0%, rgb(100 98 98) 48%, rgba(0, 0, 0, 1) 100%)',
                zIndex: '1',
                left: 'calc(50% - 185px)',
                top: '150px'
              }
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel
                sx={{ maxWidth: '400px', m: '0px auto' }}
                className="deleteModal-title-text"
              >
                {'Зміна картки'}
              </StyledLabel>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="p"
                sx={{
                  margin: '0px 0px 30px 0px',
                  wordBreak: 'break-word',
                  fontFamily: 'Kyiv-Sans-Regular !important',
                  fontSize: '14px',
                  textAlign: 'center',
                  zIndex: '99'
                }}
              >
                Ви збираєетесь змінити карткові данні. <br />
                Наступний платіж буде здійснено з вашої нової картки!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 8,
                ml: 'auto',
                mr: 'auto',
                mb: 2,
                zIndex: '99',
                alignItems: 'center'
              }}
            >
              <InputMask
                alwaysShowMask
                maskChar="X"
                mask="9999-9999-9999-9999"
                value={cardNum}
                onChange={(event) => {
                  setCardNum(event.target.value);
                  if (cardNumError) {
                    setCardNumError(null);
                  }
                }}
              >
                {(inputProps) => (
                  <TextField
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '10px'
                      },
                      '& .MuiOutlinedInput-input': {
                        fontFamily: 'unset'
                      }
                    }}
                    label="Номер платіжної картки"
                    {...inputProps}
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    size="normal"
                    inputProps={{
                      style: { textAlign: 'center', width: 220, padding: '10px !important' }
                    }}
                    InputLabelProps={{
                      style: { color: cardNumError ? 'red' : 'black' }
                    }}
                    error={Boolean(cardNumError)}
                  >
                    {cardNum}
                  </TextField>
                )}
              </InputMask>
              <img
                style={{ marginLeft: '30px', opacity: '0.7' }}
                width={50}
                src={cardChip}
                alt="cardChip"
              ></img>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center', zIndex: '99', alignItems: 'center' }}
            >
              <Box sx={{ width: '320px', display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ width: '160px', display: 'flex', justifyContent: 'space-between' }}>
              <InputMask
                  alwaysShowMask
                  maskChar="X"
                  mask="99/99"
                  value={expDate}
                  onChange={(event) => {
                    setExpDate(event.target.value);
                    if (expDateError) {
                      setExpDateError(null);
                    }
                  }}
                  // style={{ marginLeft: '35px' }}
                >
                  {(inputProps) => (
                    <TextField
                      sx={{
                        '& .MuiInputBase-input': {
                          padding: '10px'
                        },
                        '& .MuiOutlinedInput-input': {
                          fontFamily: 'unset'
                        }
                      }}
                      label="Діє до"
                      {...inputProps}
                      placeholder="XX/XX"
                      size="normal"
                      inputProps={{ style: { textAlign: 'center', width: 50 } }}
                      error={Boolean(expDateError)}
                      InputLabelProps={{
                        style: { color: expDateError ? 'red' : 'black' }
                      }}
                    >
                      {expDate}
                    </TextField>
                  )}
                </InputMask>
                <InputMask
                  alwaysShowMask
                  maskChar="X"
                  mask="999"
                  value={cvv}
                  onChange={(event) => {
                    setCvv(event.target.value);
                    if (cvvError) {
                      setCvvError(null);
                    }
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      sx={{
                        '& .MuiInputBase-input': {
                          padding: '10px'
                        },
                        '& .MuiOutlinedInput-input': {
                          fontFamily: 'unset'
                        }
                      }}
                      label="CVV"
                      {...inputProps}
                      placeholder="XXX"
                      size="normal"
                      inputProps={{ style: { textAlign: 'center', width: 50 } }}
                      error={Boolean(cvvError)}
                      InputLabelProps={{
                        style: { color: cvvError ? 'red' : 'black' }
                      }}
                    >
                      {cvv}
                    </TextField>
                  )}
                </InputMask>

              </Box>
               
                <img
                  style={{ marginTop: '2px', zIndex: 99, opacity: '0.6' }}
                  width={90}
                  height={40}
                  src={visaMaster}
                  alt="typeCard"
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              {!isLoadingBtn ? (
                <LetsGoBtn
                  className="service-card-btn"
                  width="220px"
                  margin="40px 0px 0px 0px"
                  text="Підтвердити"
                  onClick={() => {
                    if (
                      cardNum.replaceAll('-', '').length === 16 &&
                      expDate.replaceAll('/', '').length === 4 &&
                      cvv.length === 3
                    ) {
                      setIsLoadingBtn(true);
                      const changeData = {
                        cardNumber: cardNum.replaceAll('-', ''),
                        cardExpirationMonth: expDate[0] + expDate[1],
                        cardExpirationYear: expDate[3] + expDate[4],
                        cardCvv: cvv
                      };

                      apiHash
                        .sendRequest({ email: userData?.email, key: publicKey })
                        .then(async (res) => {
                          const decryptedKey = decryptData(res.result, privateKey);
                          const encryptedData = await encryptData(
                            JSON.stringify(changeData),
                            decryptedKey
                          );
                          api
                            .postDataQuery({ encodedChangeTokenCardDTO: encryptedData })
                            .then((res) => {
                              getPremiumSubscriptionThunk();
                              const iframe = document.getElementById('accSettingsPage');
                              if (iframe) {
                                iframe?.contentWindow.postMessage('REFRESH SUBSCRIPTION', '*');
                              }
                              setIsLoadingBtn(false);
                              dispatch(setIsOpenCardChangeModal(false));
                            });
                        });
                    } else {
                      if (cardNum.replaceAll('-', '').includes('X')) {
                        setCardNumError('Error');
                      }
                      if (expDate.replaceAll('/', '').includes('X')) {
                        setExpDateError('Error');
                      }
                      if (cvv.includes('X')) {
                        setCvvError('Error');
                      }
                    }
                  }}
                />
              ) : (
                <div style={{ marginTop: '40px' }}>
                  <Spinner type="small" />
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps, {
  getPremiumSubscriptionThunk: base.getPremiumSubscriptionThunk
})(CardChageModal);
