import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// material
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-redux-multilingual';
import { useNavigate } from 'react-router-dom';

// import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SearchIcon from '@mui/icons-material/Search';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DateRangeIcon from '@mui/icons-material/DateRange';
// components

import DefaultAvatar from '../../assets/defaultPostImg.jpg';
import SearchBar from '../../components/SearchBar';
import {
  setIsNewNotification,
  setIsOpenAuthModal,
  setSocketInfoMsg,
  setUserData,
  setUserNotifications,
  setUserSubscribers,
  setUserSubscriptions
} from '../../redux/mainReducer';
import AuthModal from '../../pages/modals/AuthModal';
import './Header.css';
import ApiService from '../../helpers/apiHelper';
import Notification from '../../components/Notification';
import { Spinner } from '../../components/Spinner';
import { base } from '../../redux/thunk';

//

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 83;

const RootStyle = styled('header')(({ theme }) => ({
  boxShadow: 'none',
  background:
    'linear-gradient(180deg, #ffffff 0%, rgb(255 255 255) 30.73%, rgb(255 255 255) 72.92%, rgb(255 255 255 / 0%) 100%)',
  position: 'fixed',
  maxWidth: '1440px',
  zIndex: '100',
  padding: '0px 100px',

  [theme.breakpoints.up('lg')]: {
    width: `100%`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: 0
  }
}));
const StyledButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent'
  },
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.5s',
  cursor: 'pointer',
  width: '135px',
  height: '48px',

  color: theme.palette.common.black,
  fontWeight: '400',
  fontSize: '24px',
  lineHeight: '36px',
  textTransform: 'inherit',
  fontFamily: 'Kyiv-Sans-Regular',
  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
    lineHeight: '24px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '24px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '24px',
    lineHeight: '36px'
  }
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

function Navbar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElN, setAnchorElN] = useState(null);
  const scrollRefNotif = useRef(null);
  const [notifMaxCount, setNotifMaxCount] = useState(0);
  const [notifPage, setNotifPage] = useState(0);
  const [isLoading, setIsLoadiang] = useState(false);
  const [isLoadingMore, setIsLoadiangMore] = useState(false);
  const [isScrollNeeded, setIsScrollNeeded] = useState(false);
  const isOpenAuthModal = useSelector((state) => state.global.isOpenAuthModal);
  const isNewMessage = useSelector((state) => state.global.isNewMessage);
  const isNewNotification = useSelector((state) => state.global.isNewNotification);
  const userNotifications = useSelector((state) => state.global.userNotifications);
  const socketInfoMsg = useSelector((state) => state.global.socketInfoMsg);

  const apiNotification = new ApiService('notification/notification/get-all-notification');
  const apiNotificationDelete = new ApiService('notification/notification');
  const apiNotificationDeleteAll = new ApiService('notification/notification/delete-all');

  // const apiNotification = new ApiService('/notification/get-all-notification');
  // const apiNotificationDelete = new ApiService('/notification');
  // const apiNotificationDeleteAll = new ApiService('/notification/delete-all');

  const open = Boolean(anchorEl);
  const openN = Boolean(anchorElN);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickN = (event) => {
    setIsLoadiang(true);
    setAnchorElN(event.currentTarget);
    apiNotification
      .getAll({ page: 0, size: 5 })
      .then((res) => {
        dispatch(setUserNotifications(res?.result));
        setNotifMaxCount(res?.total);
        setIsLoadiang(false);
      })
      .catch((error) => console.error(error));
  };
  const handleLoadMoreNotif = () => {
    setIsLoadiangMore(true);
    setIsScrollNeeded(true);
    apiNotification
      .getAll({ page: notifPage + 1, size: 5 })
      .then((res) => {
        dispatch(setUserNotifications([...userNotifications, ...res?.result]));
        setNotifMaxCount(res?.total);
        setNotifPage(notifPage + 1);
        setIsLoadiangMore(false);
      })
      .catch((error) => console.error(error));
  };
  const handleClickNDelete = (delType, id) => {
    setIsScrollNeeded(false);
    if (delType === 'item') {
      apiNotificationDelete
        .deleteBody([id])
        .then((res) => {
          const newUserNotifications = userNotifications.filter((el) => el.id !== id);
          dispatch(setUserNotifications([...newUserNotifications]));
          if (newUserNotifications.length === 0) {
            dispatch(setIsNewNotification(false));
          }
        })
        .catch((error) => console.error(error));
    } else {
      apiNotificationDeleteAll
        .deleteItemQuery({ userId: props?.userData?.id })
        .then((res) => {
          dispatch(setUserNotifications([]));
          dispatch(setIsNewNotification(false));
        })
        .catch((error) => console.error(error));
    }
  };

  const handleCloseN = () => {
    setAnchorElN(null);
  };
  useEffect(() => {
    if (!openN) {
      setNotifMaxCount(0);
      setNotifPage(0);
      dispatch(setUserNotifications([]));
    }
    // eslint-disable-next-line
  }, [openN]);
  useEffect(() => {
    const scrollElem =
      scrollRefNotif?.current?.children[0].childNodes[
        scrollRefNotif?.current?.children[0].childNodes.length - 1
      ];

    if (scrollElem && openN && isScrollNeeded) {
      scrollElem.scrollIntoView({ block: 'end', behavior: 'smooth' });
      setIsScrollNeeded(false);
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [userNotifications, openN]);

  const onMessageReceived = (message) => {
    if (message === 'New notification') {
      apiNotification
        .getAll({ page: 0, size: 1 })
        .then((res) => {
          dispatch(setUserNotifications([...res?.result, ...userNotifications]));
          setNotifMaxCount(res?.total);
          dispatch(setSocketInfoMsg(null));
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    if (socketInfoMsg && openN) {
      onMessageReceived(socketInfoMsg);
    }
    // eslint-disable-next-line
  }, [socketInfoMsg]);
  return (
    <RootStyle>
      <>{isOpenAuthModal && <AuthModal isOpenModal={isOpenAuthModal} />}</>

      <ToolbarStyle>
        <div
          className="header-logo"
          onClick={() => {
            navigate(`/`);
          }}
        />

        <Box className={`header-menu`}>
          <SearchBar />

          <button
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: 'none',
              backgroundColor: 'transparent',
              padding: '5px',
              cursor: 'pointer'
            }}
            onClick={() => navigate(`/${props.language}/search?question=&type=services`)}
          >
            <Tooltip title={translate('bonfair_search')}>
              <SearchIcon style={{ color: '#60A5DF' }} />
            </Tooltip>

            {/* <Typography
              variant="body2"
              component="span"
              style={{ fontFamily: 'Kyiv-Sans-Regular !important' }}
              sx={{ '&:hover': { color: '#60A5DF' } }}
            ></Typography> */}
          </button>
          {/* {!props?.userData?.id && (
            <button
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: 'none',
                backgroundColor: 'transparent',
                padding: '5px',
                cursor: 'pointer'
              }}
              onClick={handleClick}
            >
              <LanguageIcon style={{ color: '#60A5DF' }} />
              <Typography component="span" variant="body2" sx={{ '&:hover': { color: '#60A5DF' } }}>
                {' '}
                {translate('bonfair_language')}
              </Typography>
            </button>
          )} */}

          {props?.userData?.id && (
            <>
              <button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  cursor: 'pointer',
                  marginLeft: '15px'
                }}
                onClick={() => navigate(`/${props.language}/ribbon`)}
              >
                <Tooltip title={translate('bonfair_tape')}>
                  <BookOutlinedIcon style={{ color: '#60A5DF' }} />
                </Tooltip>
              </button>
              <button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  cursor: 'pointer',
                  marginLeft: '15px'
                }}
                onClick={() => navigate(`/${props.language}/messages`)}
              >
                <Tooltip title={translate('bonfair_correspondence')}>
                  <Badge color="error" variant={isNewMessage ? 'dot' : 'none'}>
                    <ChatBubbleOutlineIcon style={{ color: '#60A5DF' }} />
                  </Badge>
                </Tooltip>
              </button>
              <button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  cursor: 'pointer',
                  marginLeft: '15px',
                  marginRight: '15px'
                }}
                onClick={handleClickN}
              >
                <Tooltip title={translate('bonfair_notification')}>
                  <Badge color="error" variant={isNewNotification ? 'dot' : 'none'}>
                    <NotificationsNoneIcon style={{ color: '#60A5DF' }} />
                  </Badge>
                </Tooltip>
              </button>
              <button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  cursor: 'pointer',
                  marginRight: '15px'
                }}
                onClick={() => {
                  navigate(`/${props.language}/scheduler?tab=2`);
                }}
              >
                <Tooltip title="Календар подій">
                  <DateRangeIcon style={{ color: '#60A5DF' }} />
                </Tooltip>
              </button>
            </>
          )}
          {!props?.userData?.id ? (
            <StyledButton
              className="auth-btn"
              style={{
                border: '1px solid #1B1B1B',
                borderRadius: '50px',
                flexDirection: 'row',
                padding: '12px 24px',
                marginLeft: '30px',
                height: 'fit-content'
              }}
              disableRipple
              onClick={() => dispatch(setIsOpenAuthModal(!props.isOpenAuthModal))}
            >
              <PermIdentityIcon style={{ marginRight: '10px', color: '#60A5DF' }} />
              <Typography component="span" variant="body2">
                {' '}
                {translate('bonfair_account_enter')}
              </Typography>
            </StyledButton>
          ) : (
            <Tooltip title="Мій профіль">
              <button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  cursor: 'pointer'
                }}
                onClick={handleClick}
                // onClick={() => navigate(`/${props.language}/user-page/${props.userData.id}`)}
              >
                <Avatar
                  src={
                    props?.userData?.encodedMediaDTO
                      ? `data:${props?.userData?.encodedMediaDTO.contentType};base64, ${props?.userData?.encodedMediaDTO.encodedContent}`
                      : DefaultAvatar
                  }
                  sx={{
                    width: 24,
                    height: 24,
                    border: '1px solid #60A5DF'
                  }}
                />
                {/* <Typography component="span" variant="body2" sx={{ '&:hover': { color: '#60A5DF' } }}>
                {translate('bonfair_account')}
              </Typography> */}
              </button>
            </Tooltip>
          )}
        </Box>
        <Menu
          anchorEl={anchorElN}
          id="notification-menu"
          open={openN}
          onClose={handleCloseN}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'hidden',
              boxShadow: 'none',
              borderRadius: '5px',
              border: '1px solid black',
              paddingTop: '10px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: notifMaxCount > userNotifications.length ? '0px' : '20px',
              // width: 'fit-content',
              width: '430px',
              mt: 1

              // '&:before': {
              //   content: '""',
              //   display: 'block',
              //   position: 'absolute',
              //   top: 0,
              //   right: 43,
              //   width: 10,
              //   height: 10,
              //   borderTop: '1px solid black',
              //   borderLeft: '1px solid black',
              //   bgcolor: 'background.paper',
              //   transform: 'translateY(-50%) rotate(45deg)',
              //   zIndex: 0
              // }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Kyiv-Sans-Bold',
                  fontSize: '16px',
                  // marginBottom: '5px !important',
                  textAlign: 'left',
                  fontWeight: '700',
                  position: 'relative'
                }}
              >
                Сповіщення
              </Typography>

              {userNotifications && userNotifications.length !== 0 ? (
                <Tooltip title="Видалити все" placement="top">
                  <DeleteOutlinedIcon
                    onClick={() => handleClickNDelete()}
                    sx={{ color: '#60A5DF', mb: 0.8, cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : null}
            </Grid>
            {!isLoading ? (
              <>
                <Grid
                  ref={scrollRefNotif}
                  item
                  xs={12}
                  className="scrollableDiv"
                  sx={{
                    '&::-webkit-scrollbar': { display: 'none' },
                    maxHeight: '350px',
                    overflow: 'scroll'
                  }}
                >
                  {userNotifications && userNotifications.length !== 0 ? (
                    <Stack>
                      {userNotifications.map((el, index) => (
                        <React.Fragment key={`notificationCard-${index}`}>
                          <Notification
                            index={index}
                            el={el}
                            deleteFunc={handleClickNDelete}
                            closeFunc={handleCloseN}
                          />
                        </React.Fragment>
                      ))}
                    </Stack>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '16px',
                        marginBottom: '5px !important',
                        textAlign: 'left',
                        position: 'relative'
                      }}
                    >
                      Немає нових сповіщень
                    </Typography>
                  )}
                </Grid>
                {notifMaxCount > notifPage + 1 && userNotifications.length !== 0 && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {!isLoadingMore ? (
                      <KeyboardArrowDownOutlinedIcon
                        onClick={() => handleLoadMoreNotif()}
                        sx={{ color: '#60a5df', fontSize: '40px', cursor: 'pointer' }}
                      />
                    ) : (
                      <div style={{ marginTop: '10px' }}>
                        <Spinner type="small" />
                      </div>
                    )}
                  </Grid>
                )}
              </>
            ) : (
              <Spinner type="small" />
            )}
          </Grid>
        </Menu>
        <Menu
          anchorEl={anchorEl}
          id="language-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'hidden',
              boxShadow: 'none',
              borderRadius: '5px',
              border: '1px solid black',
              paddingTop: '10px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: '20px',
              width: '300px',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              }
              // '&:before': {
              //   content: '""',
              //   display: 'block',
              //   position: 'absolute',
              //   top: 0,
              //   right: 43,
              //   width: 10,
              //   height: 10,
              //   borderTop: '1px solid black',
              //   borderLeft: '1px solid black',
              //   bgcolor: 'background.paper',
              //   transform: 'translateY(-50%) rotate(45deg)',
              //   zIndex: 0
              // }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
              marginLeft: '5px'
            }}
          >
            <Avatar
              src={
                props?.userData?.encodedMediaDTO
                  ? `data:${props?.userData?.encodedMediaDTO.contentType};base64, ${props?.userData?.encodedMediaDTO.encodedContent}`
                  : DefaultAvatar
              }
              style={{
                width: '50px',
                height: '50px',
                border: '1px solid #60A5DF'
              }}
            />
            <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              sx={{ wordBreak: 'break-all', maxWidth: '90%' }}
            >
              {`@${props?.userData?.nickname}`}
            </Typography>
          </div>

          <MenuItem
            // selected={props.language === 'ua'}
            onClick={() => {
              navigate(`/${props.language}/user-page/${props.userData.id}?type=posts`);
            }}
            sx={{ padding: 0 }}
          >
            <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            >
              Мій профіль
            </Typography>
          </MenuItem>
          {props?.userData?.role === 'PREMIUM_USER' || props?.userData?.role === 'USER' ? (
            <MenuItem
              // selected={props.language === 'ua'}
              onClick={() => {
                navigate(`/ua/tariffs`);
              }}
              sx={{ padding: 0, borderBottom: '1px solid #60A5DF', pt: 1 }}
            >
              <Typography
                component="p"
                variant="body1"
                fontSize={16}
                lineHeight="18px"
                style={{
                  marginBottom: '10px',
                  fontFamily: 'Kyiv-Sans-Regular',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#60A5DF'
                }}
              >
                <StarBorderIcon sx={{ color: '#60A5DF', mr: 2 }} />
                Преміум
              </Typography>
            </MenuItem>
          ) : null}

          <MenuItem
            // selected={props.language === 'ua'}
            onClick={() => {
              navigate(`/ua/saved?type=posts`);
            }}
            sx={{ padding: 0, pt: 1 }}
          >
            <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            >
              Збережені
            </Typography>
          </MenuItem>
          <MenuItem
            // selected={props.language === 'ua'}
            onClick={() => {
              navigate(`/ua/account-settings`);
            }}
            sx={{ padding: 0, pt: 1 }}
          >
            <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            >
              Налаштування
            </Typography>
          </MenuItem>
          <MenuItem
            sx={{ padding: 0, borderBottom: '1px solid #60A5DF', pt: 1 }}
            // selected={props.language === 'ua'}
            onClick={() => {
              navigate(`/${props.language}/support`);
            }}
          >
            <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            >
              Підтримка
            </Typography>
          </MenuItem>
          <MenuItem
            sx={{ padding: 0, pt: 1 }}
            // selected={props.language === 'ua'}
            onClick={() => {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              dispatch(setUserData({}));
              dispatch(setUserSubscribers([]));
              dispatch(setUserSubscriptions([]));
              navigate(`/${props.language}`);
            }}
          >
            <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            >
              Вийти
            </Typography>
          </MenuItem>
        </Menu>
      </ToolbarStyle>
    </RootStyle>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    isOpenSearchBar: state.global.isOpenSearchBar,
    isOpenAuthModal: state.global.isOpenAuthModal
  };
};
export default connect(mapStateToProps, {
  getUserDataThunk: base.getUserDataThunk
})(Navbar);
