import React from 'react';

//Material
import { Grid, Paper, Stack, Typography } from '@mui/material';
// import { Stack } from '@mui/system';
import { connect, useDispatch } from 'react-redux';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import '../pages/MainPage/index.css';
import { LetsGoBtn } from './Buttons';
import { setIsOpenAuthModal } from '../redux/mainReducer';
import { makePayment } from '../helpers/payments';
// import  ImageGallery  from 'react-image-gallery';

// const useStyles = makeStyles({

//   button: {
//     '&:hover': {
//       boxShadow: '-5px 5px 3px black !important',
// width: '155px !important'
//   },
// }})
function TariffCard({ type, userData, active }) {
  const dispatch = useDispatch();
  return (
    <Paper
      // {...props}
      // key={}
      // className="serviceCard"
      sx={{
        width: '100%',
        margin: '0px',
        textAlign: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        height: '660px',
        borderRadius: '5px',
        padding: '20px 10px ',
        border: active ? '1px solid #60A5DF !important' : '1px solid lightGray',

        // boxShadow: '0px 0px 15px 0px rgba(96,165,223,1)',
        backgroundColor: active ? '#F3F8FC' : 'white'
        // position: 'relative',
      }}
    >
      {(type === 'THREE_MONTH_PREMIUM' || type === 'YEAR_PREMIUM') && (
        <Typography
          variant="p"
          sx={{
            fontFamily: 'Kyiv-Sans-Regular !important',
            fontSize: '10px',
            position: 'absolute',
            backgroundColor: '#BCF0B9',
            p: '7px 20px',
            top: '10px',
            borderRadius: '20px',
            left: 'calc(100% / 2 - 40px)'
          }}
        >
          {type === 'THREE_MONTH_PREMIUM' && 'Економія 5%'}
          {type === 'YEAR_PREMIUM' && 'Економія 7%'}
        </Typography>
      )}

      <Grid
        container
        spacing={0}
        sx={{
          width: '100%',
          margin: '0px !important',
          height: '100%',
          display: 'flex',
          alignContent: 'flex-start'
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
            borderBottom: '1px solid lightgray',
            pb: 1,
            minHeight: '185px',
            maxHeight: '185px'
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0px',
              fontFamily: 'Kyiv-Sans-Regular !important',
              fontSize: '28px',
              textAlign: 'center',
              pb: 1
            }}
          >
            {type === 'base' && 'Базовий'}
            {type === 'MONTH_PREMIUM' && 'Преміум “Місяць”'}
            {type === 'THREE_MONTH_PREMIUM' && 'Преміум “3 місяці”'}
            {type === 'YEAR_PREMIUM' && 'Преміум “Рік”'}
            <Typography
              variant="subtitle"
              sx={{
                margin: '0px',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '12px',
                textAlign: 'center',
                pt: 1
              }}
            >
              {type === 'base' &&
                'Базовий безкоштовний тариф, який надається кожному користувачу при реєстрації'}
              {type === 'MONTH_PREMIUM' &&
                'Зручний тариф з щомісячною оплатою, який надає безліч переваг власникам бізнесу '}
              {type === 'THREE_MONTH_PREMIUM' &&
                'Зручний тариф з оплатою щоквартально, який надає безліч переваг власникам бізнесу '}
              {type === 'YEAR_PREMIUM' &&
                'Зручний тариф з оплатою щорічно, який надає безліч переваг власникам бізнесу '}
            </Typography>
          </Typography>

          <Typography
            variant="subtitle"
            sx={{
              margin: '0px',
              fontFamily: 'Kyiv-Sans-Regular !important',
              fontSize: '28px',
              textAlign: 'center',
              color: '#60A5DF'
            }}
          >
            {type === 'base' && 'Безкоштовно'}
            {type === 'MONTH_PREMIUM' && '650 грн '}
            {type === 'THREE_MONTH_PREMIUM' && '1 852.5 грн'}
            {type === 'YEAR_PREMIUM' && '7 254 грн'}
            {type === 'MONTH_PREMIUM' && (
              <Typography
                variant="p"
                sx={{
                  fontFamily: 'Kyiv-Sans-Regular !important',
                  fontSize: '16px',
                  color: '#60A5DF'
                }}
              >
                /міс
              </Typography>
            )}
          </Typography>

          {type === 'THREE_MONTH_PREMIUM' && (
            <Typography
              variant="p"
              sx={{
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                color: '#60A5DF'
              }}
            >
              617.5/міс
            </Typography>
          )}
          {type === 'YEAR_PREMIUM' && (
            <Typography
              variant="p"
              sx={{
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                color: '#60A5DF'
              }}
            >
              604.5/міс
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column',
            pl: '20px'
          }}
        >
          <Stack
            spacing={1}
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-around',
              p: '20px 0px',
              minHeight: '340px'
            }}
          >
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                lineHeight: '16px',
                textAlign: 'left'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              {type === 'base' ? '1 послуга' : 'Необмежена кількість послуг для створення'}
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                lineHeight: '16px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Історія замовлень
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                lineHeight: '16px'
              }}
            >
              {type === 'base' ? (
                <CancelOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: 'red' }} />
              ) : (
                <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              )}
              Календар замовлень
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                lineHeight: '16px'
              }}
            >
              {type === 'base' ? (
                <CancelOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: 'red' }} />
              ) : (
                <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              )}
              Доступ до бустерів
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                textAlign: 'left',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                lineHeight: '16px'
              }}
            >
              {type === 'base' ? (
                <CancelOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: 'red' }} />
              ) : (
                <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              )}
              Можливість просування акаунту та послуг
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                lineHeight: '16px'
              }}
            >
              {type === 'base' ? (
                <CancelOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: 'red' }} />
              ) : (
                <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              )}
              Аналітика та звітність
            </Typography>
            {/* <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px',
                textAlign: 'left'
              }}
            >
              {type === 'base' ? (
                <CancelOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: 'red' }} />
              ) : (
                <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              )}
              Автоматизоване завантаження створених послуг
            </Typography> */}
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '16px',
                lineHeight: '16px'
              }}
            >
              {type === 'base' ? (
                <CancelOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: 'red' }} />
              ) : (
                <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              )}
              Преміум підтримка 24/7
            </Typography>
          </Stack>
        </Grid>
        {type === 'base'||active ? null : (
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <LetsGoBtn
              className="service-card-btn"
              width="200px"
              margin="25px 0px 0px 0px"
              text="Приєднатися"
              onClick={() => {
                if (userData?.id) {
                  makePayment(dispatch,type, userData?.id);
                } else {
                  dispatch(setIsOpenAuthModal(true));
                }
              }}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(TariffCard);
