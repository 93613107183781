import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import { Box, Button, Tooltip } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link } from 'react-scroll';

export const LetsGoBtn = ({ width, margin, className = '', text = 'Нумо гляньмо', ...props }) => (
  <Button
    {...props}
    variant="contained"
    className={className}
    style={{
      width: width,
      textTransform: 'inherit',
      borderRadius: '40px',
      padding: '10px',
      margin: margin,
      backgroundColor: '#EFC938',
      border: '1px solid black',
      color: 'black',
      boxShadow: 'none',
      ':hover': {
        boxShadow: '-10px 10px 3px black'
      }
    }}
  >
    {text}
  </Button>
);
export const LetsGoBtn3 = ({
  width,
  margin,
  padding = '10px',
  text = 'LetsGoBtn3',
  onClick,
  ...props
}) => (
  <Button
    {...props}
    onClick={onClick}
    variant="contained"
    sx={{
      display: 'block !important',
      zIndex: '999 !important',
      width: width,
      textTransform: 'inherit !important',
      borderRadius: '40px !important',
      padding: padding,
      margin: margin,
      backgroundColor: '#EFC938',
      border: '1px solid black !important',
      color: 'black !important',
      boxShadow: 'none !important',
      ':hover': {
        boxShadow: '-10px 10px 3px black !important',
        backgroundColor: '#EFC938'
      }
    }}
  >
    {text}
  </Button>
);
export const LetsGoBtn2 = ({
  text = 'Гайда дивитись!',
  onClick,
  icon = false,
  usedIcon,

  margin = '0px 0px 0px 16px',
  width = '250px',
  ...props
}) => (
  <Button
    {...props}
    variant="contained"
    onClick={onClick}
    sx={{
      width: width,
      textTransform: 'inherit',
      borderRadius: '40px',
      padding: '10px',

      backgroundColor: '#FFFFFF',
      border: '1px solid black',
      boxShadow: 'none',
      color: 'black',
      margin: margin,
      '&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
        color: 'rgb(159 159 159) !important',
        boxShadow: 'none !important',
        backgroundColor: 'rgb(237 237 237) !important',
        border: '1px solid rgb(159 159 159) !important',
        margin: margin
      },
      ':hover': {
        backgroundColor: 'white'
      }
    }}
  >
    {icon && !usedIcon ? <PermIdentityIcon /> : usedIcon}
    {text}
  </Button>
);
export const ContactsBtn = ({ onClick, ...props }) => (
  <Button
    {...props}
    onClick={onClick}
    variant="contained"
    style={{
      width: props?.isInContacts ? '50px' : '150px',
      textTransform: 'inherit',
      borderRadius: '40px',
      padding: '10px',
      backgroundColor: '#FFFFFF',
      border: props.disabled ? '1px solid lightgray' : '1px solid black',
      boxShadow: 'none',
      color: props.disabled ? 'lightgray' : 'black',
      fontSize: '13px'
    }}
  >
    {props?.isInContacts ? (
      <Tooltip title="Видалити з контактів">
        <PersonOffOutlinedIcon sx={{ fontSize: '20px', color: '#60A5DF' }} />
      </Tooltip>
    ) : (
      <AddIcon sx={{ color: '#60A5DF', mr: 1 }} />
    )}

    {props?.isInContacts ? '' : 'Контакти'}
  </Button>
);
export const FreeBtn = ({
  onClick,
  icon,
  text = 'Button text',
  padding = '14.5px 24px',
  disabled,
  ...props
}) => (
  <Button
    {...props}
    disabled={disabled}
    onClick={onClick}
    className={disabled && 'btnDisabled'}
    variant="contained"
    style={{
      textTransform: 'inherit',
      borderRadius: '40px',
      padding: padding,
      backgroundColor: '#FFFFFF',
      border: !disabled ? '1px solid black' : '1px solid lightgray',
      boxShadow: 'none',
      color: !disabled ? 'black' : 'lightgray',
      zIndex: 99
    }}
  >
    {icon && icon}
    {text !== 'Button text' || text.length !== 0 ? text : null}
  </Button>
);
export const ToTopBtn = ({ icon, visible, to = 'topSearch', offset = -100, ...props }) => (
  <Link
    to={to}
    spy={true}
    smooth={true}
    offset={offset}
    duration={1000}
    style={{ height: '22px', width: '22px', position: 'fixed', right: '120px', bottom: '100px' }}
  >
    <Button
      {...props}
      variant="contained"
      style={{
        textTransform: 'inherit',
        borderRadius: '30px',
        backgroundColor: '#60A5DF',
        boxShadow: 'none',
        color: 'white',
        zIndex: 99,
        padding: '15px',
        minWidth: '50px',
        position: 'fixed',
        right: '25px',
        bottom: '100px',
        visibility: visible ? 'visible' : 'hidden'
      }}
    >
      {icon && icon}{' '}
    </Button>
  </Link>
);
export const ToTopBtnFree = ({ icon, visible, ...props }) => (
  // <Box
  //   style={{ height: '22px', width: '22px', position: 'fixed', right: '120px', bottom: '100px' }}
  // >
  <Button
    {...props}
    variant="contained"
    style={{
      textTransform: 'inherit',
      borderRadius: '30px',
      backgroundColor: '#60A5DF',
      boxShadow: 'none',
      color: 'white',
      zIndex: 99,
      padding: '15px',
      minWidth: '50px',
      position: 'absolute',
      right: '-60px',
      top: '60%',
      visibility: visible ? 'visible' : 'hidden'
    }}
  >
    {icon && icon}{' '}
  </Button>
  // </Box>
);
export const PlusBtn = ({ onClick }) => (
  <Button
    onClick={onClick}
    variant="contained"
    style={{
      textTransform: 'inherit',
      borderRadius: '40px',
      padding: '14.5px 28px',
      backgroundColor: '#FFFFFF',
      border: '1px solid black',
      boxShadow: 'none',
      color: 'black'
    }}
  >
    <AddIcon sx={{ fontSize: '30px' }} htmlColor="#60A5DF" />
  </Button>
);
export const IncrementBtn = ({
  setter,
  value,
  // initOfMeasure,
  disableIncrement = false,
  disableDecrement = false,
  colorOfQty
}) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-around', maxWidth: '105px ' }}>
    <Button
      onClick={() => setter(value + 1)}
      variant="contained"
      id="buttonIncrement"
      disabled={disableIncrement}
      sx={{
        textTransform: 'inherit',
        borderRadius: '10px',
        padding: '0px',
        backgroundColor: '#e2f2ff',
        border: 'none',
        boxShadow: 'none',
        color: 'black',
        minWidth: 'unset',
        width: '22px !important',
        height: '22px !important'
      }}
    >
      <AddIcon
        sx={{
          color: 'black',
          fontSize: '21px',
          ':hover': {
            color: '#60A5DF'
          }
        }}
      />
    </Button>
    {/* {`${value} ${initOfMeasure}.`} */}
    <p style={{ margin: '0', color: colorOfQty }}>{`${value} од.`}</p>
    <Button
      onClick={() => setter(value - 1)}
      disabled={disableDecrement}
      variant="contained"
      id="buttonDecrement"
      sx={{
        textTransform: 'inherit',
        borderRadius: '10px',
        padding: '0px',
        backgroundColor: '#e2f2ff',
        border: 'none',
        boxShadow: 'none',
        color: 'black',
        minWidth: 'unset',
        width: '22px !important',
        height: '22px !important'
      }}
    >
      <RemoveIcon
        sx={{
          color: 'black',
          fontSize: '21px',
          ':hover': {
            color: '#60A5DF'
          }
        }}
      />
    </Button>
  </Box>
);
