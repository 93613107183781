import CloseIcon from '@mui/icons-material/Close';
import { Slide } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import mainReducer from './redux/mainReducer';
import reportWebVitals from './reportWebVitals';
import translations from './services/translations';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/uk';

const root = ReactDOM.createRoot(document.getElementById('root'));

const reducers = combineReducers(Object.assign({}, { Intl, global: mainReducer }));
// const setRegion = () => {
//   if (
//     window.navigator.language === 'ru' ||
//     window.navigator.language === 'ru-RU' ||
//     window.navigator.language === 'uk-UA' ||
//     window.navigator.language === 'uk'
//   ) {
//     return 'ua';
//   } else {
//     return 'en';
//   }
// };
// const store = createStore(reducers, { Intl: { locale: setRegion() } });
const store = createStore(reducers, { Intl: { locale: 'ua' } }, applyMiddleware(thunk));
const snackRef = React.createRef();

root.render(
  <Provider store={store}>
    <IntlProvider translations={translations}>
      <HelmetProvider>
        <BrowserRouter>
          <SnackbarProvider
            ref={snackRef}
            action={(id) => (
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  fontSize: 18,
                  width: '20px',
                  height: '20px',
                  padding: '2px',
                  ':hover': {
                    backgroundColor: '#fdfdfd1a',
                    borderRadius: '10px'
                  }
                }}
                onClick={() => snackRef.current.closeSnackbar(id)}
              />
            )}
            maxSnack={5}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            TransitionComponent={Slide}
            autoHideDuration={2000}
          >
            <LocalizationProvider adapterLocale={'uk'} dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </HelmetProvider>
    </IntlProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
