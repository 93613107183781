import ApiService from '../../../helpers/apiHelper';
import {
  setBlockedUsers,
  setIsNewMessage,
  setIsNewNotification,
  setIsOpenAuthModal,
  setMutedUsers,
  setPremiumSubscription,
  setUserData,
  setUserSavedPosts,
  setUserSavedServices,
  setUserSubscribers,
  setUserSubscriptions,
  setUsersWhoBlocked
} from '../../mainReducer';

const getPremiumSubscriptionThunk = () => (dispatch) => {
  const apiSubscriprion = new ApiService('auth/get-subscription');
  apiSubscriprion
    .getAll()
    .then((res) => {
      dispatch(setPremiumSubscription(res.result));
    })
    .catch((error) => dispatch(setPremiumSubscription(null)));
};
const getUserSubscribersThunk = (id) => (dispatch) => {
  const apiSubscribers = new ApiService('auth/get-subscribers');
  apiSubscribers.getAll({ userId: id }).then((res) => {
    dispatch(setUserSubscribers(res.result));
  });
};
const getUserSubscriptionsThunk = (id) => (dispatch) => {
  const apiSubscriptions = new ApiService('auth/get-subscriptions');
  apiSubscriptions.getAll({ userId: id }).then((res) => {
    dispatch(setUserSubscriptions(res.result));
  });
};
const getBlockedUsersThunk = (id) => (dispatch) => {
  const apiBlockedUsers = new ApiService('auth/blocked-users');
  apiBlockedUsers.getAll().then((res) => dispatch(setBlockedUsers(res.result)));
};
const getMutedUsersThunk = (id) => (dispatch) => {
  const apiMutedUsers = new ApiService('auth/muted-users/get-muted-users');
  apiMutedUsers.getAll().then((res) => dispatch(setMutedUsers(res.result)));
};
const getUserDataThunk =
  (isAuth = false, setIsLoading, resetForm, navigate, language) =>
  (dispatch) => {
    const apiUser = new ApiService('auth/user');
    const apiSubscribers = new ApiService('auth/get-subscribers');
    const apiSubscriptions = new ApiService('auth/get-subscriptions');
    const apiBlockedUsers = new ApiService('auth/blocked-users');
    const apiMutedUsers = new ApiService('auth/muted-users/get-muted-users');
    const apiSubscriprion = new ApiService('auth/get-subscription');

    if (isAuth) {
      apiUser.getAll().then((res) => {
        dispatch(setIsOpenAuthModal(false));
        setIsLoading(false);
        dispatch(setUserData(res.result));
        if (
          !window.location.href.includes('search') &&
          !window.location.href.includes('post') &&
          !window.location.href.includes('service') &&
          !window.location.href.includes('messages')
        ) {
          navigate(`/${language}/user-page/${res.result.id}?type=posts`);
        }
        apiSubscribers
          .getAll({ userId: res.result.id })
          .then((res) => dispatch(setUserSubscribers(res.result)));
        apiSubscriptions
          .getAll({ userId: res.result.id })
          .then((res) => dispatch(setUserSubscriptions(res.result)));
        apiBlockedUsers.getAll().then((res) => dispatch(setBlockedUsers(res.result)));
        apiMutedUsers.getAll().then((res) => dispatch(setMutedUsers(res.result)));
        if (res?.result?.role === 'PREMIUM_USER') {
          apiSubscriprion.getAll().then((res) => dispatch(setPremiumSubscription(res.result)));
        } else {
          dispatch(setPremiumSubscription(null));
        }

        if (res.result?.usersWhoBlocked && res.result?.usersWhoBlocked.length !== 0) {
          dispatch(setUsersWhoBlocked(res.result?.usersWhoBlocked));
        }

        dispatch(setUserSavedPosts(res.result.savedPosts));
        dispatch(setUserSavedServices(res.result.savedServices));
        dispatch(setIsNewMessage(res.result?.isNewMessage ? res.result?.isNewMessage : false));
        dispatch(
          setIsNewNotification(
            res.result?.isNewNotification ? res.result?.isNewNotification : false
          )
        );

        resetForm();
      });
    } else {
      apiUser.getAll().then((res) => {
        dispatch(setUserData(res.result));
        dispatch(setIsNewMessage(res.result.isNewMessage));
        dispatch(
          setIsNewNotification(
            res.result?.isNewNotification ? res.result?.isNewNotification : false
          )
        );
        dispatch(setUserSavedPosts(res.result.savedPosts));
        dispatch(setUserSavedServices(res.result.savedServices));
        if (res.result?.usersWhoBlocked && res.result?.usersWhoBlocked.length !== 0) {
          dispatch(setUsersWhoBlocked(res.result?.usersWhoBlocked));
        }
        apiSubscribers.getAll({ userId: res.result.id }).then((res) => {
          dispatch(setUserSubscribers(res.result));
        });
        apiSubscriptions.getAll({ userId: res.result.id }).then((res) => {
          dispatch(setUserSubscriptions(res.result));
        });
        apiBlockedUsers.getAll().then((res) => dispatch(setBlockedUsers(res.result)));
        apiMutedUsers.getAll().then((res) => dispatch(setMutedUsers(res.result)));
        if (res?.result?.role === 'PREMIUM_USER') {
          apiSubscriprion.getAll().then((res) => dispatch(setPremiumSubscription(res.result)));
        } else {
          dispatch(setPremiumSubscription(null));
        }
      });
    }
  };
// eslint-disable-next-line
export default {
  getUserDataThunk,
  getUserSubscribersThunk,
  getUserSubscriptionsThunk,
  getBlockedUsersThunk,
  getMutedUsersThunk,
  getPremiumSubscriptionThunk
};
