import React from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { StyledTitle } from '../../components/StyledComponents';
import { setIsOpenPremiumModal } from '../../redux/mainReducer';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import { LetsGoBtn } from '../../components/Buttons';
import './DeleteModal.css';
import { base } from '../../redux/thunk';
import ApiService from '../../helpers/apiHelper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1200,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 60px 50px 60px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function PremiumChooseModal({
  isOpenModal,
  premiumSubscription,
  getPremiumSubscriptionThunk,
  iframe
}) {
  const dispatch = useDispatch();
  const api = new ApiService('auth/change-premium-plan');
  const chooseFunc = (type) => {
    api.postDataQuery({ subscriptionType: type }).then((res) => {
      const iframe = document.getElementById('accSettingsPage')
      if (iframe) {
        iframe?.contentWindow.postMessage(
          'REFRESH SUBSCRIPTION',

          '*'
        );
      }
      dispatch(setIsOpenPremiumModal(false));
      getPremiumSubscriptionThunk();
    });
  };

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenPremiumModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenPremiumModal(false));
            }}
          />

          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}
            >
              <StyledTitle sx={{ marginBottom: '20px !important' }} className="tariffsModal-title">
                {'Тарифні плани'}
              </StyledTitle>
              <Typography
                variant="p"
                sx={{
                  fontFamily: 'Kyiv-Sans-Regular !important',
                  fontSize: '18px',
                  textAlign: 'center !important'
                }}
              >
                Більше можливостей, більше зручності
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  minHeight: '285px',
                  minWidth: '295px',
                  padding: '24px 20px',
                  border:
                    premiumSubscription?.subscriptionType &&
                    premiumSubscription?.subscriptionType !== 'MONTH_PREMIUM'
                      ? '1px solid lightgray'
                      : '1px solid #60A5DF',
                  backgroundColor:
                    premiumSubscription?.subscriptionType &&
                    premiumSubscription?.subscriptionType === 'MONTH_PREMIUM'
                      ? '#F3F8FC'
                      : 'white',
                  borderRadius: '5px'
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '28px',
                    textAlign: 'center !important'
                  }}
                >
                  Преміум “Місяць”
                </Typography>
                <Typography
                  variant="subtitle"
                  sx={{
                    margin: '20px 0px',
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '28px',
                    textAlign: 'center',
                    color: '#60A5DF'
                  }}
                >
                  {'650 грн '}

                  <Typography
                    variant="p"
                    sx={{
                      fontFamily: 'Kyiv-Sans-Regular !important',
                      fontSize: '16px',
                      color: '#60A5DF'
                    }}
                  >
                    /міс
                  </Typography>
                </Typography>
                {premiumSubscription?.nextSubscriptionType !== 'MONTH_PREMIUM'&& premiumSubscription?.subscriptionType !== 'MONTH_PREMIUM' ? (
                  <LetsGoBtn
                    className="service-card-btn"
                    width="170px"
                    margin="35px 0px 0px 0px"
                    text="Обрати"
                    onClick={() => {
                      chooseFunc('MONTH_PREMIUM');
                    }}
                  />
                ) : (
                  <Typography
                    variant="p"
                    sx={{
                      fontFamily: 'Kyiv-Sans-Regular !important',
                      fontSize: '18px',
                      color: '#60A5DF'
                    }}
                  >
                    Обрано
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  minHeight: '285px',
                  minWidth: '295px',
                  margin: '0px 20px',
                  padding: '24px 10px',
                  border:
                    premiumSubscription?.subscriptionType &&
                    premiumSubscription?.subscriptionType !== 'THREE_MONTH_PREMIUM'
                      ? '1px solid lightgray'
                      : '1px solid #60A5DF',
                  backgroundColor:
                    premiumSubscription?.subscriptionType &&
                    premiumSubscription?.subscriptionType === 'THREE_MONTH_PREMIUM'
                      ? '#F3F8FC'
                      : 'white',
                  borderRadius: '5px',
                  position: 'relative'
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '10px',
                    position: 'absolute',
                    backgroundColor: '#BCF0B9',
                    p: '7px 20px',
                    top: '-15px',
                    borderRadius: '20px',
                    left: 'calc(100% / 2 - 50px)'
                  }}
                >
                  {'Економія 5%'}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '28px',
                    textAlign: 'center !important'
                  }}
                >
                  Преміум “3 місяці”
                </Typography>
                <Typography
                  variant="subtitle"
                  sx={{
                    margin: '20px 0px 0px 0px',
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '28px',
                    textAlign: 'center',
                    color: '#60A5DF'
                  }}
                >
                  {'1 852.5 грн '}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '16px',
                    textAlign: 'center',
                    color: '#60A5DF'
                  }}
                >
                  617.5/міс
                </Typography>
                {premiumSubscription?.nextSubscriptionType !== 'THREE_MONTH_PREMIUM'&& premiumSubscription?.subscriptionType !== 'THREE_MONTH_PREMIUM' ? (
                  <LetsGoBtn
                    className="service-card-btn"
                    width="170px"
                    margin="35px 0px 0px 0px"
                    text="Обрати"
                    onClick={() => {
                      chooseFunc('THREE_MONTH_PREMIUM');
                    }}
                  />
                ) : (
                  <Typography
                    variant="p"
                    sx={{
                      fontFamily: 'Kyiv-Sans-Regular !important',
                      fontSize: '18px',
                      color: '#60A5DF'
                    }}
                  >
                    Обрано
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  minHeight: '285px',
                  minWidth: '295px',
                  padding: '24px 20px',
                  border:
                    premiumSubscription?.subscriptionType &&
                    premiumSubscription?.subscriptionType !== 'YEAR_PREMIUM'
                      ? '1px solid lightgray'
                      : '1px solid #60A5DF',
                  backgroundColor:
                    premiumSubscription?.subscriptionType &&
                    premiumSubscription?.subscriptionType === 'YEAR_PREMIUM'
                      ? '#F3F8FC'
                      : 'white',
                  borderRadius: '5px',
                  position: 'relative'
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '10px',
                    position: 'absolute',
                    backgroundColor: '#BCF0B9',
                    p: '7px 20px',
                    top: '-15px',
                    borderRadius: '20px',
                    left: 'calc(100% / 2 - 50px)'
                  }}
                >
                  {'Економія 7%'}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '28px',
                    textAlign: 'center !important'
                  }}
                >
                  Преміум “Рік”
                </Typography>
                <Typography
                  variant="subtitle"
                  sx={{
                    margin: '20px 0px 0px 0px',
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '28px',
                    textAlign: 'center',
                    color: '#60A5DF'
                  }}
                >
                  {'7 254 грн '}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Kyiv-Sans-Regular !important',
                    fontSize: '16px',
                    textAlign: 'center',
                    color: '#60A5DF'
                  }}
                >
                  604.5/міс
                </Typography>
                {premiumSubscription?.nextSubscriptionType !== 'YEAR_PREMIUM'&& premiumSubscription?.subscriptionType !== 'YEAR_PREMIUM' ? (
                  <LetsGoBtn
                    className="service-card-btn"
                    width="170px"
                    margin="35px 0px 0px 0px"
                    text="Обрати"
                    onClick={() => {
                      chooseFunc('YEAR_PREMIUM');
                    }}
                  />
                ) : (
                  <Typography
                    variant="p"
                    sx={{
                      fontFamily: 'Kyiv-Sans-Regular !important',
                      fontSize: '18px',
                      color: '#60A5DF'
                    }}
                  >
                    Обрано
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    premiumSubscription: state.global.premiumSubscription
  };
};
export default connect(mapStateToProps, {
  getPremiumSubscriptionThunk: base.getPremiumSubscriptionThunk
})(PremiumChooseModal);
