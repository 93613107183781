import styled from '@emotion/styled';
import { TabList } from '@mui/lab';

export const StyledSubTabs = styled(TabList)`
  .MuiTabs-flexContainer {
    justify-content: space-between;
    max-width: 190px;
  }

  .MuiButtonBase-root.MuiTab-root {
    position: absolute !important;
    // background: white;
    border-radius: 5px;
    padding: 6px 16px !important;
    color: #9d9d9d !important;

    min-height: 30px !important;

    :hover {
      background: #deecf8;
      color: black !important;
    }
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    position: relative !important;
    z-index: 0 !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    word-wrap: brack-word;
    background: #deecf8;
    color: black !important;
    /* main green */
  }
  .MuiButtonBase-root.MuiTab-root {
    text-align: left;
    position: relative !important;
    // padding: 10px;
    width: fit-content !important;
    z-index: 0 !important;
    margin: 0 !important;
    font-family: Public Sans, sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    // :hover {
    //   background-color: pink;
    // }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
export const StyledSubTabsScheduler = styled(TabList)`
  .MuiTabs-flexContainer {
    justify-content: space-between;
    width: 390px;
  }

  .MuiButtonBase-root.MuiTab-root {
    position: absolute !important;
    // background: white;
    border-radius: 0px;
    padding: 6px 16px !important;
    color: #9d9d9d !important;

    min-height: 30px !important;

    :hover {
      background: #deecf8;
      color: black !important;
    }
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    position: relative !important;
    z-index: 0 !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    word-wrap: brack-word;
    background: #deecf8;
    color: #60a5df !important;
    font-family: Kyiv-Sans-Bold !important;
    padding: 10px !important;
    border-bottom: 2px solid #60a5df !important;
    /* main green */
  }
  .MuiTab-root.Mui-disabled {
    text-align: left;
    position: relative !important;
    padding: 10px !important;
    width: fit-content !important;
    z-index: 0 !important;
    margin: 0 !important;
    font-family: Kyiv-Sans-Light !important;
    // color: black !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    // :hover {
    //   background-color: pink;
    // }
  }
  .MuiButtonBase-root.MuiTab-root:not(.Mui-disabled) {
    text-align: left;
    position: relative !important;
    padding: 10px !important;
    width: fit-content !important;
    z-index: 0 !important;
    margin: 0 !important;
    font-family: Kyiv-Sans-Light !important;
    color: black !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    // :hover {
    //   background-color: pink;
    // }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
