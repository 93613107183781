import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Page';
// import { useNavigate } from 'react-router-dom';

//Material

// import ReplyIcon from '@mui/icons-material/Reply';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Autocomplete, Avatar, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import BoosterPng from '../../assets/Booster.png';
import DefaultAvatar from '../../assets/defaultPostImg.jpg';
import { LetsGoBtn, LetsGoBtn2 } from '../../components/Buttons';
import FreeTariffCard from '../../components/FreeTariffCard';
import { StyledTitle } from '../../components/StyledComponents';
import TariffCard from '../../components/TariffCard';
import '../MainPage/index.css';

// import { Spinner } from '../../components/Spinner';
const data = [
  {
    name: 'Січень',
    uv: 0
  },
  {
    name: 'Лютий',
    uv: 30
  },
  {
    name: 'Березень',
    uv: 20
  },
  {
    name: 'Квітень',
    uv: 27
  },
  {
    name: 'Травень',
    uv: 18
  },
  {
    name: 'Червень',
    uv: 21
  },
  {
    name: 'Липень',
    uv: 23
  },
  {
    name: 'Серпень',
    uv: 16
  },
  {
    name: 'Вересень',
    uv: 12
  },
  {
    name: 'Жовтень',
    uv: 17
  },
  {
    name: 'Листопад',
    uv: 25
  },
  {
    name: 'Грудень',
    uv: null
  }
];
function TariffPage(props) {
  const [sortType, setSortType] = useState('Послуг продано за період');
  const [period, setPeriod] = useState('Рік');
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [day, setDay] = useState(new Date().toISOString());
  const navigate = useNavigate();
  useEffect(() => {
    if (
      props?.userData &&
      Object.keys(props?.userData).length !== 0 &&
      props?.userData?.role !== 'PREMIUM_USER' &&
      props?.userData?.role !== 'USER'
    ) {
      navigate('/ua');
    } else {
    }
    // eslint-disable-next-line
  }, [props?.userData]);

  return (
    <Page
      title="Тарифи"
      style={{
        display: 'flex',
        marginTop: '90px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <Grid
        container
        style={{
          maxWidth: '1440px',
          paddingTop: '65px'
        }}
        spacing={3}
      >
        <Grid item xs={12}>
          <StyledTitle
            className="bottomBlock-title"
            sx={{
              textTransform: 'none',
              fontSize: '32px !important',
              fontFamily: 'Kyiv-Sans-Regular !important'
            }}
          >
            {props?.userData && props?.userData?.role !== 'PREMIUM_USER'
              ? 'Тарифні плани'
              : 'Керування Преміум-акаунтом'}
          </StyledTitle>
        </Grid>
        {props?.userData && props?.userData?.role !== 'PREMIUM_USER' ? (
          <>
            {' '}
            <Grid item xs={12}>
              <FreeTariffCard />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', m: '20px 0px' }}>
              <Typography
                variant="p"
                sx={{
                  margin: '0px',
                  wordBreak: 'break-word',
                  fontFamily: 'Kyiv-Sans-Regular !important',
                  fontSize: '28px',
                  textAlign: 'left'
                }}
              >
                Більше можливостей, більше зручності
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TariffCard type="base" active={!props?.premiumSubscription?.subscriptionType} />
            </Grid>
            <Grid item xs={3}>
              <TariffCard
                type="MONTH_PREMIUM"
                active={props?.premiumSubscription?.subscriptionType === 'MONTH_PREMIUM'}
              />
            </Grid>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <TariffCard
                type="THREE_MONTH_PREMIUM"
                active={props?.premiumSubscription?.subscriptionType === 'THREE_MONTH_PREMIUM'}
              />
            </Grid>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <TariffCard
                type="YEAR_PREMIUM"
                active={props?.premiumSubscription?.subscriptionType === 'YEAR_PREMIUM'}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="p"
                      sx={{
                        margin: '0px 0px 20px',
                        wordBreak: 'break-word',
                        fontFamily: 'Kyiv-Sans-Bold !important',
                        fontSize: '18px',
                        textAlign: 'left'
                      }}
                    >
                      Ваші бустери
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '1px solid #C8C8C8',
                        padding: '32px 20px',
                        borderRadius: '5px'
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          margin: '0px 10px',
                          wordBreak: 'break-word',
                          fontFamily: 'Kyiv-Sans-Regular !important',
                          fontSize: '20px',
                          textAlign: 'left',
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <img
                          width={42}
                          height={30}
                          src={BoosterPng}
                          alt="booster"
                          style={{ marginRight: '10px' }}
                        />
                        {`Баланс: 0`}
                      </Typography>
                      <LetsGoBtn
                        className="service-card-btn"
                        width="200px"
                        margin="0px "
                        text="Поповнити баланс"
                        onClick={() => {
                          // navigate(`/${language}/service/${el.id}`)
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="p"
                      sx={{
                        margin: '0px 0px 20px',
                        wordBreak: 'break-word',
                        fontFamily: 'Kyiv-Sans-Bold !important',
                        fontSize: '18px',
                        textAlign: 'left'
                      }}
                    >
                      Ваш акаунт
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '1px solid #C8C8C8',
                        padding: '20px',
                        borderRadius: '5px'
                      }}
                    >
                      <Avatar
                        onClick={() => {
                          navigate(`/ua/user-page/${props?.userData?.id}?type=posts`);
                        }}
                        src={
                          props?.userData?.encodedMediaDTO
                            ? `data:${props?.userData?.encodedMediaDTO.contentType};base64, ${props?.userData?.encodedMediaDTO.encodedContent}`
                            : DefaultAvatar
                        }
                        sx={{
                          width: 70,
                          height: 70,
                          border: '1px solid #60A5DF',
                          cursor: 'pointer',
                          boxShadow: 'none'
                        }}
                      />
                      <Stack sx={{ width: '100%', marginLeft: '10px' }}>
                        {props?.userData?.name && props?.userData?.surname && (
                          <Typography
                            variant="subtitle"
                            sx={{
                              fontFamily: 'Public Sans,sans-serif !important',
                              fontSize: '18px',
                              textAlign: 'left',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              navigate(`/ua/user-page/${props?.userData.id}?type=posts`);
                            }}
                            // src={
                            //   user?.encodedMediaDTO
                            //     ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                            //     : DefaultAvatar
                            // }
                          >
                            {`${props?.userData?.name} ${props?.userData?.surname}`}
                          </Typography>
                        )}

                        <Typography
                          variant="subtitle"
                          sx={{
                            fontFamily: 'Public Sans,sans-serif !important',
                            fontSize:
                              props?.userData?.name && props?.userData.surname ? '14px' : '18px',
                            textAlign: 'left',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            navigate(`/ua/user-page/${props?.userData.id}?type=posts`);
                          }}
                        >
                          {`@${props?.userData?.nickname}`}
                        </Typography>
                      </Stack>
                      <LetsGoBtn2
                        className="service-card-btn"
                        width="150px"
                        margin="0px "
                        text="Просувати"
                        onClick={() => {
                          // navigate(`/${language}/service/${el.id}`)
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        margin: '0px 0px 20px',
                        wordBreak: 'break-word',
                        fontFamily: 'Kyiv-Sans-Bold !important',
                        fontSize: '18px',
                        textAlign: 'left'
                      }}
                    >
                      Аналітика
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      {period === 'Рік' ? (
                        <DatePicker
                          value={dayjs().year(year)}
                          minDate={dayjs().year(2023)}
                          openTo="year"
                          views={['year']}
                          sx={{ mr: 2, width: 100 }}
                          onChange={(value, context) => setYear(new Date(value).getFullYear())}
                        />
                      ) : null}
                      {period === 'Місяць' ? (
                        <DatePicker
                          value={dayjs().month(month)}
                          openTo="month"
                          views={['month']}
                          sx={{ mr: 2, width: 130 }}
                          onChange={(value, context) => setMonth(new Date(value).getMonth())}
                        />
                      ) : null}
                      {period === 'День' ? (
                        <DatePicker
                          value={dayjs(day)}
                          openTo="day"
                          format="MMMM DD"
                          views={['month', 'day']}
                          sx={{ mr: 2, width: 150 }}
                          onChange={(value, context) => setDay(new Date(value).toISOString())}
                        />
                      ) : null}

                      <Autocomplete
                        sx={{ width: '130px', mr: 2 }}
                        disableClearable
                        onKeyPress={(e) => {
                          e.preventDefault();
                        }}
                        options={['Рік', 'Місяць', 'День']}
                        value={period}
                        onChange={(event, value) => setPeriod(value)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Обери категорію послуги*" />
                        )}
                      />
                      <Autocomplete
                        sx={{ width: '250px' }}
                        disableClearable
                        onKeyPress={(e) => {
                          e.preventDefault();
                        }}
                        options={[
                          'Послуг продано за період',
                          'Коштів отримано за період',
                          'Нових підписників за період',
                          'Відвідування сторінки за період'
                        ]}
                        value={sortType}
                        onChange={(event, value) => setSortType(value)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Обери категорію послуги*" />
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #C8C8C8',
                      padding: '20px',
                      borderRadius: '5px',
                      backgroundColor: 'white'
                    }}
                  >
                    <Stack sx={{ width: '100%', marginLeft: '10px' }}>
                      <Typography
                        variant="subtitle"
                        sx={{
                          fontFamily: 'Kyiv-Sans-Regular !important',
                          fontSize: '16px',
                          textAlign: 'left',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        {`Продано послуг`}
                        <Box
                          sx={{
                            borderRadius: '5px',
                            backgroundColor: '#DDF8DB',
                            color: '#67C062',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '3px'
                          }}
                        >
                          <TrendingUpIcon sx={{ color: '#67C062', fontSize: '14px', mr: 0.5 }} />
                          {'20,5 %'}
                        </Box>
                      </Typography>

                      <Typography
                        variant="subtitle"
                        sx={{
                          fontFamily: 'Kyiv-Sans-Regular !important',
                          fontSize: 23,
                          textAlign: 'left',
                          cursor: 'pointer',
                          color: '#60A5DF'
                        }}
                      >
                        {`25`}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #C8C8C8',
                      padding: '20px',
                      borderRadius: '5px',
                      backgroundColor: 'white'
                    }}
                  >
                    <Stack sx={{ width: '100%', marginLeft: '10px' }}>
                      <Typography
                        variant="subtitle"
                        sx={{
                          fontFamily: 'Kyiv-Sans-Regular !important',
                          fontSize: '16px',
                          textAlign: 'left',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        {`Дохід`}
                        <Box
                          sx={{
                            borderRadius: '5px',
                            backgroundColor: '#FFE4E1',
                            color: '#F64D36',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '3px'
                          }}
                        >
                          <TrendingDownIcon sx={{ color: '#F64D36', fontSize: '14px', mr: 0.5 }} />
                          {'2,5 %'}
                        </Box>
                      </Typography>

                      <Typography
                        variant="subtitle"
                        sx={{
                          fontFamily: 'Kyiv-Sans-Regular !important',
                          fontSize: 23,
                          textAlign: 'left',
                          cursor: 'pointer',
                          color: '#60A5DF'
                        }}
                      >
                        {`7620 грн`}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #C8C8C8',
                      padding: '20px',
                      borderRadius: '5px',
                      backgroundColor: 'white'
                    }}
                  >
                    <Stack sx={{ width: '100%', marginLeft: '10px' }}>
                      <Typography
                        variant="subtitle"
                        sx={{
                          fontFamily: 'Kyiv-Sans-Regular !important',
                          fontSize: '16px',
                          textAlign: 'left',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        {`Нових підписників`}
                        <Box
                          sx={{
                            borderRadius: '5px',
                            backgroundColor: '#FFE4E1',
                            color: '#F64D36',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '3px'
                          }}
                        >
                          <TrendingDownIcon sx={{ color: '#F64D36', fontSize: '14px', mr: 0.5 }} />
                          {'1,5 %'}
                        </Box>
                      </Typography>

                      <Typography
                        variant="subtitle"
                        sx={{
                          fontFamily: 'Kyiv-Sans-Regular !important',
                          fontSize: 23,
                          textAlign: 'left',
                          cursor: 'pointer',
                          color: '#60A5DF'
                        }}
                      >
                        {`1`}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ResponsiveContainer
                width="100%"
                height={440}
                style={{
                  border: '1px solid #C8C8C8',
                  borderRadius: '5px',
                  backgroundColor: 'white'
                }}
              >
                <LineChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 100,
                    right: 50,
                    left: 20,
                    bottom: 30
                  }}
                >
                  <CartesianGrid strokeDasharray="0 0" vertical={false} />
                  <XAxis tickSize={20} axisLine={false} tickLine={false} dataKey="name" />
                  <YAxis tickSize={20} axisLine={false} tickLine={false} />
                  <Tooltip
                    formatter={(value, name) => {
                      switch (sortType) {
                        case 'Послуг продано за період':
                          return [value, 'Продано послуг'];
                        case 'Коштів отримано за період':
                          return [value, 'Коштів отримано'];
                        case 'Нових підписників за період':
                          return [value, 'Нових підписників'];
                        case 'Відвідування сторінки за період':
                          return [value, 'Відвідало сторінку'];
                        default:
                          return;
                      }
                    }}
                  />

                  <Line
                    type="monotone"
                    dataKey="uv"
                    stroke="#60A5DF"
                    dot={false}
                    activeDot={{ r: 2 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Grid>
          </>
        )}
      </Grid>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    premiumSubscription: state.global.premiumSubscription
  };
};
export default connect(mapStateToProps)(TariffPage);
