export const errorHandler = (errorsObj, translate) => {
  if (errorsObj?.info) {
    if (errorsObj?.info?.user === "User doesn't exists") {
      return errorsObj;
    } else if (errorsObj?.info?.verify === 'No such key') {
      return 'Некоректний тимчасовий ключ';
    } else if (
      errorsObj?.info?.email &&
      errorsObj?.info?.email.includes('There is an account with that email address')
    ) {
      return 'Акаунт з такою поштою вже існує';
    } else if (errorsObj?.info?.message === 'There is an account with that nickname') {
      return 'Акаунт з таким нікнеймом вже існує';
    } else if (
      errorsObj?.info?.nickname &&
      errorsObj?.info?.nickname.includes('There is an account with that nickname')
    ) {
      return 'Користувач з таким нікнеймом, вже зареєстрований';
    } else if (
      errorsObj?.info?.name &&
      !errorsObj?.info?.surname &&
      errorsObj.info?.name.includes('Name is not correct')
    ) {
      return "Вказано некоректне ім'я";
    } else if (
      errorsObj?.info?.surname &&
      !errorsObj?.info?.name &&
      errorsObj?.info?.surname.includes('Surname is not correct')
    ) {
      return 'Вказано некоректне прізвище';
    } else if (
      errorsObj?.info?.surname &&
      errorsObj?.info?.surname.includes('Surname is not correct') &&
      errorsObj?.info?.name &&
      errorsObj?.info?.name.includes('Name is not correct')
    ) {
      return "Вказано некоректне прізвище та ім'я";
    } else if (errorsObj?.info?.message === 'Invalid email or password') {
      return 'Не вірний логін або пароль';
    } else if (
      !errorsObj?.info?.auth &&
      typeof errorsObj?.info === 'string' &&
      errorsObj?.info?.includes('There is no user with email')
    ) {
      return 'Така електронна пошта не зареєстрована';
    } else if (errorsObj?.info?.auth === 'Account is not activated') {
      return 'Акаунт не активований! Перевірте електронну пошту.';
    } else if (errorsObj?.info?.result && errorsObj?.info?.result.includes("isn't activated")) {
      return 'Акаунт не активований! Перевірте електронну пошту.';
    } else if (errorsObj?.info?.number === 'має відповідати шаблону "\\+[0-9]+"') {
      return 'Номер телефону має відповідати шаблону +380*********';
    } else if (
      errorsObj?.info?.result &&
      errorsObj?.info?.result === 'User with active orders cannot delete account'
    ) {
      return 'User with active orders cannot delete account';
    } else if (
      errorsObj?.info?.result &&
      errorsObj?.info?.result === 'The selected photo is not in a supported format'
    ) {
      return 'The selected photo is not in a supported format';
    }
  } else if (errorsObj?.result) {
    if (
      errorsObj?.result &&
      errorsObj?.result.includes('Account with email') &&
      errorsObj?.result.includes("doesn't exists")
    ) {
      return 'Такого акаунту не існує!';
    } else if (errorsObj?.result && errorsObj?.result === 'Authentication failed') {
      return 'Не вірний пароль!';
    } else if (errorsObj?.result && errorsObj?.result === 'The account is temporarily blocked') {
      return 'Акаунт заблоковано!';
    } else {
      return errorsObj;
    }
  } else if (errorsObj?.message) {
    if (errorsObj?.message === 'Account can`t create more then 1 service') {
      return 'Ви вичерпали ліміт доступних послуг!';
    } else if (errorsObj?.message === 'Account can`t create more then 5 services') {
      return 'Ви вичерпали ліміт доступних послуг у пробному тарифі!';
    } else if (errorsObj?.message === "Service isn't active") {
      return 'Послуга не активна!';
    } else if (errorsObj?.message === 'Only premium users can activate services') {
      return 'Послугу не активовано!';
    } else {
      return errorsObj;
    }
  } else {
    return errorsObj;
  }
};
