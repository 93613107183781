import React, { useEffect, useState } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography
} from '@mui/material';

import styled from '@emotion/styled';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslate } from 'react-redux-multilingual';
import * as Yup from 'yup';
import {
  disableCyrillicRegExp,
  emailRegExp,
  passwordRegExp,
  whiteSpaceRegExp
} from '../../services/regularExp';
import './AuthModal.css';
// import { useSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { connect, useDispatch } from 'react-redux';
import { base } from '../../redux/thunk';

import { LetsGoBtn3 } from '../../components/Buttons';
import { StyledLabel } from '../../components/StyledComponents';
import ApiService from '../../helpers/apiHelper';
import { setIsOpenAuthModal } from '../../redux/mainReducer';
// import {  encode as btoa, decode as atob } from 'base-64';
// import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import hmacSHA512 from 'crypto-js/hmac-sha512';

import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';
import { Spinner } from '../../components/Spinner';
import { decryptData, encryptData, generateKeys } from '../../helpers/cryptoHelper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 670,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  display: 'flex',
  justifyContent: 'center'
};

// const StyledButton = styled(LoadingButton)(({ theme }) => ({
//   color: theme.palette.common.white,
//   fontFamily: 'Noto Sans',
//   textTransform: 'uppercase',
//   borderRadius: '50px',
//   maxWidth: 'fit-content',
//   minWidth: '150px',
//   padding: '0 20px',
//   '&:hover': { backgroundColor: 'transparent' },
//   boxShadow: '0px 7px 10px 1px rgb(145 158 171 / 30%)',
//   background:
//     'linear-gradient(353.54deg, #043B1F 3.03%, #0D7834 37.41%, #0E8D3D 67.77%, #0B9552 104.82%)'
// }));
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  // '& .MuiInput-root.Mui-focused:after': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:hover:not(.Mui-disabled):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:not(.Mui-error):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  margin: '5px 0px'
});
const CssTextField2FA = styled(TextField)({
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #60A5DF'
  },
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  '& .MuiInput-root.Mui-focused:after': {
    borderBottom: '2px #000000 solid'
  },
  '& .MuiInput-root:hover:not(.Mui-disabled):before': {
    borderBottom: '2px #000000 solid'
  },
  '& .MuiInput-root:not(.Mui-error):before': {
    borderBottom: '2px #000000 solid'
  },
  margin: '5px auto'
});

function AuthModal({ isOpenModal, language, getUserDataThunk, getBlockedUsersThunk }) {
  const [actionType, setActionType] = useState('auth');
  const [restoreStep, setRestoreStep] = useState(0);
  const [isLoadingBtn, setIsLoadingBtn] = useState(true);
  const [screenType, setScreenType] = useState('desktop');
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
  const [isLoading, setIsLoading] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  // const { btoa } = window;
  //const userData = useSelector((state=>state.global.userData)) //for test
  //console.log(userData) // for test
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);

  const keysGenerator = () => {
    const keys = generateKeys();
    if (keys) {
      setPublicKey(keys?.publicKey);
      setPrivateKey(keys?.privateKey);
      setIsLoadingBtn(false);
    }
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        keysGenerator();
      }, [200]);
    }
    return () => {
      mounted = false;
      clearTimeout(keysGenerator);
    };
    // eslint-disable-next-line
  }, []);
  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  const apiAuth = new ApiService('auth/auth', translate);
  const apiHash = new ApiService('auth/hash', translate);

  const apiReg = new ApiService('auth/reg', translate);
  const apiRestore = new ApiService('auth/get-code', translate);
  const apiRestoreCode = new ApiService('auth/verify', translate);
  const apiResetPass = new ApiService('auth/reset-password', translate);
  const api2FA = new ApiService('auth/auth-2fa', translate);
  const apiGetLink = new ApiService('auth/send-activation-link', translate);

  const schema = Yup.object().when((values, schema) => {
    if (actionType === 'auth') {
      return schema.shape({
        email: Yup.string()
          .matches(emailRegExp, translate('error_regExp_email'))
          .required(translate('error_required')),
        password: Yup.string().required(translate('error_required'))
      });
    } else if (actionType === 'registration') {
      return schema.shape({
        email: Yup.string()
          .matches(emailRegExp, translate('error_regExp_email'))
          .required(translate('error_required')),
        name: Yup.string()
          .min(2, translate('error_input_lenght_min_2'))
          .max(100, 'Максимально 100 символів')
          .required(translate('error_required')),

        surname: Yup.string()
          .min(2, translate('error_input_lenght_min_2'))
          .max(100, 'Максимально 100 символів')
          .required(translate('error_required')),
        nickname: Yup.string()
          .min(2, translate('error_input_lenght_min_2'))
          .max(30, 'Максимально 30 символів')
          .matches(disableCyrillicRegExp, translate('error_regExp_nickname'))
          .test('Нікнейм не може включати пробіл', 'Нікнейм не може включати пробіл', (value) =>
            whiteSpaceRegExp.test(value)
          )
          .matches(whiteSpaceRegExp, translate('Нікнейм не може включати пробіл'))
          .required(translate('error_required')),
        password: Yup.string()
          .required(translate('error_required'))
          .matches(passwordRegExp, translate('error_regExp_password'))

          .min(10, translate('error_input_lenght_min_10'))
          .max(256, translate('error_input_lenght_max_256')),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], translate('error_password_match'))
          .required(translate('error_required')),
        agreement: Yup.boolean()
          .required(translate('error_agreement_alert'))
          .oneOf([true], translate('error_agreement_alert'))
      });
    } else if (actionType === 'restore' && restoreStep === 0) {
      return schema.shape({
        email: Yup.string()
          .matches(emailRegExp, translate('error_regExp_email'))
          .required(translate('error_required'))
      });
    } else if (actionType === 'restore' && restoreStep === 1) {
      return schema.shape({
        verify: Yup.string().required(translate('error_required'))
      });
    } else if (actionType === 'restore' && restoreStep === 2) {
      return schema.shape({
        password: Yup.string()
          .required(translate('error_required'))
          .matches(passwordRegExp, translate('error_regExp_password')),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], translate('error_password_match'))
          .required(translate('error_required'))
      });
    } else if (actionType === '2FA') {
      return schema.shape({
        verify: Yup.string().required(translate('error_required'))
      });
    }
  });
  function base64url(source) {
    // Encode in classical base64
    let encodedSource = Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      surname: '',
      nickname: '',
      agreement: false,
      verify: ''
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
      //FOR FEATURE ENCRYPTING
      // const pass=AES.encrypt( values.password, '12345').toString()
      // console.log(pass)
      // console.log('DECRYPT', AES.decrypt(pass,'12345').toString(enc.Utf8))

      // const encryptData = (data, decryptedKey) => {
      //   // const crypt = new JSEncrypt();
      //   // crypt.setPublicKey(publicKey);
      //   // const encrypted = crypt.encrypt(data);
      //   const encrypted = AES.encrypt(data, decryptedKey).toString();
      //   return encrypted;
      // };

      if (actionType === 'auth') {
        setIsLoading(true);
        apiHash
          .sendRequest({ email: values.email, key: publicKey })
          .then(async (res) => {
            // console.log('keysInPublic', publicKey);
            const decryptedKey = decryptData(res.result, privateKey);
            // console.log('decryptedKey', decryptedKey);
            const encryptedPassword = await encryptData(values.password, decryptedKey);
            // console.log('encryptedPassword', encryptedPassword);

            const tokenHeader = {
              alg: 'HS512',
              typ: 'JWT'
            };
            // const stringifiedHeader = btoa(JSON.stringify(tokenHeader));
            const stringifiedHeader = Utf8.parse(JSON.stringify(tokenHeader));
            let encodedHeader = base64url(stringifiedHeader);

            const data = {
              sub: `${values.email}:${encryptedPassword}`
            };
            // const stringifiedData = btoa(JSON.stringify(data));
            const stringifiedData = Utf8.parse(JSON.stringify(data));
            let encodedData = base64url(stringifiedData);
            let token = encodedHeader + '.' + encodedData;

            let secret =
              'UG7QRc1D/T4NnKjO2UWZ4dxv49/ZdtcwTu0XdR2wHsTt7iMqzez+UbGjOyPXwLyGrT6asLqwC7hmWEuQpV6sUg==';

            let signature = hmacSHA512(token, secret);
            signature = base64url(signature);
            let signedToken = token + '.' + signature;
            const authHeaders = {
              Authorization: 'Bearer ' + signedToken
            };

            apiAuth
              .authRequest(authHeaders)
              .then(async (res) => {
                if (
                  res &&
                  res?.info &&
                  res?.info?.message &&
                  res?.info?.message === 'enable 2FA-auth'
                ) {
                  setActionType('2FA');
                  setIsLoading(false);
                } else {
                  await localStorage.setItem('accessToken', `Bearer ${res.accessToken}`);
                  await localStorage.setItem('refreshToken', `${res.refreshToken}`);
                  getUserDataThunk(true, setIsLoading, resetForm, navigate, language);
                  getBlockedUsersThunk();
                  setIsLoading(false);
                  dispatch(setIsOpenAuthModal(false));
                }
              })
              .catch((error) => {
                setIsLoading(false);

                if (typeof error !== 'object') {
                  enqueueSnackbar(error, { variant: 'error' });
                  if (error === 'Акаунт заблоковано!') {
                    setActionType('accBlocked');
                  }
                } else {
                  if (Object.keys(error).length !== 0) {
                    setErrors(error);
                  } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
                }
              });
          })
          .catch((error) => {
            setIsLoading(false);

            if (typeof error !== 'object') {
              enqueueSnackbar(error, { variant: 'error' });
            } else {
              if (Object.keys(error).length !== 0) {
                setErrors(error);
              } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
            }
          });
      } else if (actionType === 'registration') {
        setIsLoading(true);
        const regData = { ...values };
        delete regData.verify;
        delete regData.agreement;
        regData.name = regData.name[0].toUpperCase() + regData.name.slice(1);
        regData.surname = regData.surname[0].toUpperCase() + regData.surname.slice(1);

        apiHash
          .sendRequest({ email: values.email, key: publicKey })
          .then(async (res) => {
            // console.log('keysInPublic', publicKey);
            const decryptedKey = decryptData(res.result, privateKey);
            // console.log('decryptedKey', decryptedKey);
            const encryptedData = await encryptData(JSON.stringify(regData), decryptedKey);
            // console.log('encryptedData', encryptedPassword);

            apiReg
              .sendRequest({ email: values?.email, encodedStringRegistrationDTO: encryptedData })
              .then((res) => {
                setIsLoading(false);
                setActionType('confirmMail');
                resetForm();
                setShowPassword({ password: false, confirmPassword: false });
              })
              .catch((error) => {
                setIsLoading(false);
                if (typeof error !== 'object') {
                  enqueueSnackbar(error, { variant: 'error' });
                } else {
                  if (Object.keys(error).length !== 0) {
                    setErrors(error);
                  } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
                }
              });
          })
          .catch((error) => {
            setIsLoading(false);

            if (typeof error !== 'object') {
              enqueueSnackbar(error, { variant: 'error' });
            } else {
              if (Object.keys(error).length !== 0) {
                setErrors(error);
              } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
            }
          });
      } else if (actionType === 'restore' && restoreStep === 0) {
        setIsLoading(true);
        apiRestore
          .sendRequest({ email: values.email })
          .then((res) => {
            setRestoreStep(1);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            if (typeof error !== 'object') {
              enqueueSnackbar(error, { variant: 'error' });
              if (error === 'Акаунт не активований! Перевірте електронну пошту.') {
                setActionType('confirmMailSec');
                apiGetLink
                  .getAll({ email: values?.email })
                  .then((res) =>
                    enqueueSnackbar('Код активації надіслано.', { variant: 'success' })
                  );
              }
            } else {
              if (Object.keys(error).length !== 0) {
                setErrors(error);
              } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
            }
          });
      } else if (actionType === 'restore' && restoreStep === 1) {
        setIsLoading(true);
        apiRestoreCode
          .sendRequest({ verify: values.verify })
          .then((res) => {
            setIsLoading(false);
            setRestoreStep(2);
            localStorage.setItem('accessToken', `Bearer ${res.info.token}`);
          })
          .catch((error) => {
            setIsLoading(false);
            if (typeof error !== 'object') {
              enqueueSnackbar(error, { variant: 'error' });
              if (error === 'Акаунт не активований! Перевірте електронну пошту.') {
                setActionType('confirmMailSec');
                apiGetLink
                  .getAll({ email: values?.email })
                  .then((res) =>
                    enqueueSnackbar('Код активації надіслано.', { variant: 'success' })
                  );
              }
            } else {
              if (Object.keys(error).length !== 0) {
                setErrors(error);
              } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
            }
          });
      } else if (actionType === 'restore' && restoreStep === 2) {
        setIsLoading(true);
        apiHash
          .sendRequest({ email: values.email, key: publicKey })
          .then(async (res) => {
            const decryptedKey = decryptData(res.result, privateKey);
            // console.log('decryptedKey', decryptedKey);
            const encryptedData = await encryptData(
              JSON.stringify({
                password: values.password,
                confirmPassword: values.confirmPassword
              }),
              decryptedKey
            );
            apiResetPass
              .resetPass({ encodedObjectResetPasswordDTO: encryptedData })
              .then((res) => {
                setIsLoading(false);
                setActionType('auth');
                setRestoreStep(0);
                resetForm();
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
              })
              .catch((error) => {
                setIsLoading(false);

                if (typeof error !== 'object') {
                  enqueueSnackbar(error, { variant: 'error' });
                } else {
                  if (Object.keys(error).length !== 0) {
                    setErrors(error);
                  } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
                }
              });
          })
          .catch((error) => {
            setIsLoading(false);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            if (typeof error !== 'object') {
              enqueueSnackbar(error, { variant: 'error' });
              if (error === 'Акаунт не активований! Перевірте електронну пошту.') {
                setActionType('confirmMailSec');
                apiGetLink
                  .getAll({ email: values?.email })
                  .then((res) =>
                    enqueueSnackbar('Код активації надіслано.', { variant: 'success' })
                  );
              }
            } else {
              if (Object.keys(error).length !== 0) {
                setErrors(error);
              } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
            }
          });
      } else if (actionType === '2FA') {
        setIsLoading(true);
        api2FA
          .sendRequest({ verify: values.verify })
          .then(async (res) => {
            await localStorage.setItem('accessToken', `Bearer ${res.accessToken}`);
            await localStorage.setItem('refreshToken', `${res.refreshToken}`);
            getUserDataThunk(true, setIsLoading, resetForm, navigate, language);
            getBlockedUsersThunk();
            dispatch(setIsOpenAuthModal(false));
            setActionType('auth');
            setRestoreStep(0);
            resetForm();
          })
          .catch((error) => {
            setIsLoading(false);
            if (typeof error !== 'object') {
              enqueueSnackbar(error, { variant: 'error' });
            } else {
              if (Object.keys(error).length !== 0) {
                setErrors(error);
              } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
            }
          });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    submitForm,
    resetForm,
    setFieldValue
  } = formik;
  const handleClickShowPassword = (type) => {
    if (type === 'password') {
      setShowPassword({ ...showPassword, password: !showPassword.password });
    } else if (type === 'confirmPassword') {
      setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Modal
        BackdropProps={{ id: 'modalBackdrop' }}
        id="authModal"
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenAuthModal(false));
          setActionType('auth');
          setRestoreStep(0);
          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_auth_modal"
      >
        <Box sx={style} className="bonfair_auth_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_auth_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenAuthModal(false));
              setActionType('auth');
              setRestoreStep(0);
              resetForm();
            }}
          />

          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
            >
              {!isLoading ? (
                <Grid container spacing={screenType !== 'desktop' ? 1 : 3}>
                  {actionType === 'auth' && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <StyledLabel className="authModal-title-text">
                          {translate('bonfair_auth_modal')}
                        </StyledLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid item xs={12} className="authModal-inputs-first">
                          <CssTextField
                            label={
                              <div style={{ display: 'flex' }}>
                                <MailOutlineIcon sx={{ mr: 1 }} />{' '}
                                {translate('bonfair_auth_modal_inputType_email')}
                              </div>
                            }
                            variant="standard"
                            fullWidth
                            size="normal"
                            inputProps={{ style: { textAlign: 'left' } }}
                            error={Boolean(touched.email && errors.email)}
                            {...getFieldProps('email')}
                            value={values.email}
                          >
                            {values.email}
                          </CssTextField>
                          {touched.email && errors.email && (
                            <div
                              style={{
                                color: 'red',
                                margin: '5px 0px',
                                fontSize: '14px',
                                fontFamily: 'Kyiv-Sans-Regular'
                              }}
                            >
                              {errors.email}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          <CssTextField
                            fullWidth
                            type={showPassword.password ? 'text' : 'password'}
                            size="normal"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    style={{ zIndex: '999' }}
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPassword('password')}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword.password ? (
                                      <VisibilityOff fontSize="small" />
                                    ) : (
                                      <Visibility fontSize="small" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            inputProps={{ style: { textAlign: 'left' } }}
                            error={Boolean(touched.password && errors.password)}
                            {...getFieldProps('password')}
                            value={values.password}
                            label={
                              <div style={{ display: 'flex' }}>
                                <LockOutlinedIcon sx={{ mr: 1 }} />{' '}
                                {translate('bonfair_auth_modal_input_password')}
                              </div>
                            }
                            variant="standard"
                          >
                            {values.password}
                          </CssTextField>
                          {touched.password && errors.password && (
                            <div
                              style={{
                                color: 'red',
                                margin: '5px 0px',
                                fontSize: '14px',
                                fontFamily: 'Kyiv-Sans-Regular'
                              }}
                            >
                              {errors.password}
                            </div>
                          )}
                        </Grid>
                      </Grid>{' '}
                    </>
                  )}
                  {actionType === 'registration' && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <StyledLabel className="authModal-title-text">
                          {translate('bonfair_auth_modal_reg')}
                        </StyledLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} className="authModal-inputs-first">
                            <CssTextField
                              label={translate('bonfair_contact_modal_input_name')}
                              variant="standard"
                              fullWidth
                              size="normal"
                              inputProps={{
                                style: { textAlign: 'left', textTransform: 'capitalize' }
                              }}
                              error={Boolean(touched.name && errors.name)}
                              {...getFieldProps('name')}
                              value={values.name}
                            >
                              {values.name}
                            </CssTextField>
                            {touched.name && errors.name && (
                              <div
                                style={{
                                  color: 'red',
                                  margin: '5px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.name}
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <CssTextField
                              label={translate('bonfair_contact_modal_input_secondName')}
                              variant="standard"
                              fullWidth
                              size="normal"
                              inputProps={{
                                style: { textAlign: 'left', textTransform: 'capitalize' }
                              }}
                              error={Boolean(touched.surname && errors.surname)}
                              {...getFieldProps('surname')}
                              value={values.surname}
                            >
                              {values.surname}
                            </CssTextField>
                            {touched.surname && errors.surname && (
                              <div
                                style={{
                                  color: 'red',
                                  margin: '5px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.surname}
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <CssTextField
                              label={translate('bonfair_contact_modal_input_nikname')}
                              variant="standard"
                              fullWidth
                              size="normal"
                              inputProps={{ style: { textAlign: 'left' } }}
                              error={Boolean(touched.nickname && errors.nickname)}
                              {...getFieldProps('nickname')}
                              value={values.nickname}
                            >
                              {values.nickname}
                            </CssTextField>
                            {touched.nickname && errors.nickname && (
                              <div
                                style={{
                                  color: 'red',
                                  margin: '5px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.nickname}
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <CssTextField
                              label={translate('bonfair_auth_modal_inputType_email')}
                              variant="standard"
                              fullWidth
                              size="normal"
                              inputProps={{ style: { textAlign: 'left' } }}
                              error={Boolean(touched.email && errors.email)}
                              {...getFieldProps('email')}
                              value={values.email}
                            >
                              {values.email}
                            </CssTextField>
                            {touched.email && errors.email && (
                              <div
                                style={{
                                  color: 'red',
                                  margin: '5px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.email}
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <CssTextField
                            type={showPassword.password ? 'text' : 'password'}
                            fullWidth
                            size="normal"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    style={{ zIndex: '999' }}
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPassword('password')}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword.password ? (
                                      <VisibilityOff fontSize="small" />
                                    ) : (
                                      <Visibility fontSize="small" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            inputProps={{ style: { textAlign: 'left' } }}
                            error={Boolean(touched.password && errors.password)}
                            {...getFieldProps('password')}
                            value={values.password}
                            label={translate('bonfair_auth_modal_input_password')}
                            variant="standard"
                          >
                            {values.password}
                          </CssTextField>
                          {touched.password && errors.password && (
                            <div
                              style={{
                                color: 'red',
                                margin: '5px 0px',
                                fontSize: '14px',
                                fontFamily: 'Kyiv-Sans-Regular'
                              }}
                            >
                              {errors.password}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          <CssTextField
                            fullWidth
                            size="normal"
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    style={{ zIndex: '999' }}
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPassword('confirmPassword')}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword.confirmPassword ? (
                                      <VisibilityOff fontSize="small" />
                                    ) : (
                                      <Visibility fontSize="small" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            inputProps={{ style: { textAlign: 'left' } }}
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            {...getFieldProps('confirmPassword')}
                            value={values.confirmPassword}
                            label={translate('bonfair_auth_modal_input_confirmPassword')}
                            variant="standard"
                          >
                            {values.confirmPassword}
                          </CssTextField>
                          {touched.confirmPassword && errors.confirmPassword && (
                            <div
                              style={{
                                color: 'red',
                                margin: '5px 0px',
                                fontSize: '14px',
                                fontFamily: 'Kyiv-Sans-Regular'
                              }}
                            >
                              {errors.confirmPassword}
                            </div>
                          )}
                          <Typography
                            variant="body2"
                            component="span"
                            style={{
                              marginTop: '20px',
                              fontSize: '12px',
                              alignItems: 'center',
                              display: 'flex',
                              color: 'black'
                            }}
                          >
                            <ReportGmailerrorredIcon sx={{ color: '#60A5DF', mr: 0.5 }} />
                            Пароль має включати:
                          </Typography>
                          <ul
                            style={{
                              fontSize: '12px',
                              color: 'black',
                              marginTop: '0px',
                              paddingLeft: '25px',
                              fontFamily: 'Kyiv-Sans-Light !important'
                            }}
                          >
                            <li
                              style={{
                                fontFamily: 'Kyiv-Sans-Light '
                              }}
                            >
                              тільки латинські літери
                            </li>
                            <li
                              style={{
                                fontFamily: 'Kyiv-Sans-Light '
                              }}
                            >
                              1 велику літеру
                            </li>
                            <li
                              style={{
                                fontFamily: 'Kyiv-Sans-Light '
                              }}
                            >
                              1 цифру
                            </li>
                            <li
                              style={{
                                fontFamily: 'Kyiv-Sans-Light '
                              }}
                            >
                              1 маленьку літеру
                            </li>
                            <li
                              style={{
                                fontFamily: 'Kyiv-Sans-Light '
                              }}
                            >
                              мінімальна довжина поля 10 символів
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {(actionType === 'confirmMail' || actionType === 'confirmMailSec') && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <StyledLabel className="authModal-title-text">
                          {translate('bonfair_auth_modal_reg_confirm')}
                        </StyledLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="authModal-inputs-first"
                        style={{ marginTop: '20px' }}
                      >
                        <Typography
                          // component="span"

                          className="authModal-main-text"
                          style={{ marginBottom: '10px', textAlign: 'center !important' }}
                        >
                          {translate('bonfair_auth_modal_reg_confirm_l')}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {actionType === 'restore' && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <StyledLabel className="authModal-title-text">
                          {translate('bonfair_auth_modal_restore')}
                        </StyledLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        {restoreStep === 0 && (
                          <Grid
                            item
                            xs={12}
                            className="authModal-inputs-first"
                            style={{ marginTop: '20px' }}
                          >
                            <Typography
                              className="authModal-main-text"
                              style={{
                                marginBottom: '10px',
                                textAlign: 'left',
                                fontSize: '16px',
                                fontFamily: 'Kyiv-Sans-Light'
                              }}
                            >
                              {translate('bonfair_auth_modal_l')}
                            </Typography>
                            <CssTextField
                              label={
                                <div style={{ display: 'flex' }}>
                                  <MailOutlineIcon sx={{ mr: 1 }} />{' '}
                                  {translate('bonfair_auth_modal_inputType_email')}
                                </div>
                              }
                              variant="standard"
                              fullWidth
                              size="normal"
                              inputProps={{ style: { textAlign: 'left' } }}
                              error={Boolean(touched.email && errors.email)}
                              {...getFieldProps('email')}
                              value={values.email}
                            >
                              {values.email}
                            </CssTextField>
                            {touched.email && errors.email && (
                              <div
                                style={{
                                  color: 'red',
                                  margin: '5px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.email}
                              </div>
                            )}
                          </Grid>
                        )}
                        {restoreStep === 1 && (
                          <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            className="authModal-inputs-first"
                          >
                            <Typography
                              className="authModal-main-text"
                              style={{
                                marginBottom: '30px',
                                textAlign: 'center',
                                fontSize: '16px',
                                fontFamily: 'Kyiv-Sans-Light'
                              }}
                            >
                              {translate('bonfair_auth_modal_l_2')}
                            </Typography>
                            <InputMask
                              alwaysShowMask
                              maskChar="X"
                              mask="999999"
                              value={values.verify}
                              {...getFieldProps('verify')}
                            >
                              {(inputProps) => (
                                <CssTextField2FA
                                  {...inputProps}
                                  placeholder="XXXXXX"
                                  size="normal"
                                  inputProps={{ style: { textAlign: 'center' } }}
                                  error={Boolean(touched.verify && errors.verify)}
                                >
                                  {values.verify}
                                </CssTextField2FA>
                              )}
                            </InputMask>
                            {touched.verify && errors.verify && (
                              <div
                                style={{
                                  color: 'red',
                                  margin: '5px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.verify}
                              </div>
                            )}
                          </Grid>
                        )}
                        {restoreStep === 2 && (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                component="span"
                                sx={{
                                  margin: '20px 0px 0px !important',
                                  display: 'inline-block',
                                  color: 'black'
                                }}
                              >
                                Створіть новий надійний пароль.
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                style={{
                                  marginTop: '20px',
                                  fontSize: '12px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  color: 'black'
                                }}
                              >
                                <ReportGmailerrorredIcon sx={{ color: '#60A5DF', mr: 0.5 }} />
                                Пароль має включати:
                              </Typography>
                              <ul
                                style={{
                                  fontSize: '12px',
                                  color: 'black',
                                  marginTop: '0px',
                                  paddingLeft: '25px',
                                  fontFamily: 'Kyiv-Sans-Light !important'
                                }}
                              >
                                <li
                                  style={{
                                    fontFamily: 'Kyiv-Sans-Light '
                                  }}
                                >
                                  тільки латинські літери
                                </li>
                                <li
                                  style={{
                                    fontFamily: 'Kyiv-Sans-Light '
                                  }}
                                >
                                  1 велику літеру
                                </li>
                                <li
                                  style={{
                                    fontFamily: 'Kyiv-Sans-Light '
                                  }}
                                >
                                  1 цифру
                                </li>
                                <li
                                  style={{
                                    fontFamily: 'Kyiv-Sans-Light '
                                  }}
                                >
                                  1 маленьку літеру
                                </li>
                                <li
                                  style={{
                                    fontFamily: 'Kyiv-Sans-Light '
                                  }}
                                >
                                  мінімальна довжина поля 10 символів
                                </li>
                              </ul>
                              <CssTextField
                                type={showPassword.password ? 'text' : 'password'}
                                fullWidth
                                size="normal"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        style={{ zIndex: '999' }}
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('password')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword.password ? (
                                          <VisibilityOff fontSize="small" />
                                        ) : (
                                          <Visibility fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                inputProps={{ style: { textAlign: 'left' } }}
                                error={Boolean(touched.password && errors.password)}
                                {...getFieldProps('password')}
                                value={values.password}
                                label={translate('bonfair_auth_modal_input_password')}
                                variant="standard"
                              >
                                {values.password}
                              </CssTextField>
                              {touched.password && errors.password && (
                                <div
                                  style={{
                                    color: 'red',
                                    margin: '5px 0px',
                                    fontSize: '14px',
                                    fontFamily: 'Kyiv-Sans-Regular'
                                  }}
                                >
                                  {errors.password}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                              <Typography
                                component="span"
                                sx={{
                                  margin: '20px 0px 0px !important',
                                  display: 'inline-block',
                                  color: 'black'
                                }}
                              >
                                Підтвердіть Ваш новий пароль.
                              </Typography>
                              <CssTextField
                                fullWidth
                                size="normal"
                                type={showPassword.confirmPassword ? 'text' : 'password'}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        style={{ zIndex: '999' }}
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('confirmPassword')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword.confirmPassword ? (
                                          <VisibilityOff fontSize="small" />
                                        ) : (
                                          <Visibility fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                inputProps={{ style: { textAlign: 'left' } }}
                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                {...getFieldProps('confirmPassword')}
                                value={values.confirmPassword}
                                label={'Введіть пароль ще раз*'}
                                variant="standard"
                              >
                                {values.confirmPassword}
                              </CssTextField>
                              {touched.confirmPassword && errors.confirmPassword && (
                                <div
                                  style={{
                                    color: 'red',
                                    margin: '5px 0px',
                                    fontSize: '14px',
                                    fontFamily: 'Kyiv-Sans-Regular'
                                  }}
                                >
                                  {errors.confirmPassword}
                                </div>
                              )}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                  {actionType !== 'confirmMail' &&
                    actionType !== '2FA' &&
                    actionType !== 'confirmMailSec' &&
                    actionType !== 'accBlocked' && (
                      <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                      >
                        {!isLoadingBtn ? (
                          <LetsGoBtn3
                            id="loginBtn"
                            disabled={
                              (actionType === 'auth' && values.email.length === 0) ||
                              (actionType === 'auth' && errors.email) ||
                              (actionType === 'auth' && values.password.length === 0) ||
                              (actionType === 'auth' && errors.password) ||
                              (actionType === 'registration' && values.name.length === 0) ||
                              (actionType === 'registration' && errors.name) ||
                              (actionType === 'registration' && values.surname.length === 0) ||
                              (actionType === 'registration' && errors.surname) ||
                              (actionType === 'registration' && values.nickname.length === 0) ||
                              (actionType === 'registration' && errors.nickname) ||
                              (actionType === 'registration' && values.email.length === 0) ||
                              (actionType === 'registration' && errors.email) ||
                              (actionType === 'registration' && values.password.length === 0) ||
                              (actionType === 'registration' && errors.password) ||
                              (actionType === 'registration' &&
                                values.confirmPassword.length === 0) ||
                              (actionType === 'registration' && errors.confirmPassword) ||
                              (actionType === 'registration' && values.agreement === false) ||
                              (actionType === 'registration' && errors.agreement) ||
                              (actionType === 'restore' &&
                                restoreStep === 0 &&
                                values.email.length === 0) ||
                              (actionType === 'restore' && restoreStep === 0 && errors.email) ||
                              (actionType === 'restore' &&
                                restoreStep === 1 &&
                                values.verify.length === 0) ||
                              (actionType === 'restore' && restoreStep === 1 && errors.verify) ||
                              (actionType === 'restore' &&
                                restoreStep === 2 &&
                                values.password.length === 0) ||
                              (actionType === 'restore' && restoreStep === 2 && errors.password)
                            }
                            size="large"
                            onClick={() => {
                              submitForm();
                            }}
                            width={257}
                            style={{ textTransform: 'inherit' }}
                            text={
                              (actionType === 'auth' && 'Увійти') ||
                              (actionType === 'registration' && 'Зареєструватись') ||
                              (actionType === 'restore' && restoreStep === 0 && 'Продовжити') ||
                              (actionType === 'restore' && restoreStep === 1 && 'Підтвердити') ||
                              (actionType === 'restore' && restoreStep === 2 && 'Підтвердити')
                            }
                          />
                        ) : (
                          <div style={{ marginTop: '10px' }}>
                            <Spinner type="small" />
                          </div>
                        )}
                      </Grid>
                    )}

                  {actionType === 'registration' && (
                    <>
                      <Grid item xs={12} style={{ display: 'flex' }}>
                        <Checkbox
                          disableRipple
                          checked={values?.agreement}
                          onChange={(event) => setFieldValue('agreement', event.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          sx={{
                            '&:hover': { color: '#60A5DF', backgroundColor: 'transparent' },
                            '&.Mui-checked': {
                              color: '#60A5DF'
                            }
                          }}
                        />

                        <p>
                          Підтверджуючи реєстрацію, я приймаю умови{' '}
                          <span
                            onClick={() => window.open(`/${language}/agreement`)}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            користувальницької угоди.*
                          </span>
                        </p>
                      </Grid>
                      {touched.agreement && errors.agreement && (
                        <div
                          style={{
                            width: 'fit-content',
                            color: 'red',
                            margin: '0px auto',
                            fontSize: '14px',
                            fontFamily: 'Kyiv-Sans-Regular'
                          }}
                        >
                          {errors.agreement}
                        </div>
                      )}
                    </>
                  )}
                  {actionType === '2FA' && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <StyledLabel className="authModal-title-text">
                          {`Увімкнено двофакторну аутентифікацію`}
                        </StyledLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className="authModal-inputs-first"
                      >
                        <Typography
                          className="authModal-main-text"
                          style={{
                            marginBottom: '30px',
                            textAlign: 'left',
                            fontSize: '16px',
                            fontFamily: 'Kyiv-Sans-Light'
                          }}
                        >
                          {`На вказану вами електронну пошту надіслано листа з кодом для авторизації, будь ласка введіть його нижче.`}
                        </Typography>

                        <InputMask
                          alwaysShowMask
                          maskChar="X"
                          mask="999999"
                          value={values.verify}
                          {...getFieldProps('verify')}
                        >
                          {(inputProps) => (
                            <CssTextField2FA
                              {...inputProps}
                              placeholder="XXXXXX"
                              size="normal"
                              inputProps={{ style: { textAlign: 'center' } }}
                              error={Boolean(touched.verify && errors.verify)}
                            >
                              {values.verify}
                            </CssTextField2FA>
                          )}
                        </InputMask>

                        {touched.verify && errors.verify && (
                          <div
                            style={{
                              color: 'red',
                              margin: '5px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.verify}
                          </div>
                        )}
                      </Grid>
                    </>
                  )}
                  {actionType === '2FA' && (
                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                    >
                      <LetsGoBtn3
                        id="loginBtn2FA"
                        disabled={actionType === '2FA' && values.verify.length === 0}
                        size="large"
                        onClick={() => {
                          submitForm();
                        }}
                        width={257}
                        style={{ textTransform: 'inherit' }}
                        text={'Увійти'}
                      />
                    </Grid>
                  )}
                  {actionType !== 'confirmMail'&&actionType!=='accBlocked' && (
                    <Grid
                      item
                      xs={12}
                      style={
                        actionType === 'auth'
                          ? {
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '10px',
                              flexDirection: 'column',
                              alignItems: 'center'
                            }
                          : {
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '10px',
                              flexDirection: 'column',
                              alignItems: 'center'
                            }
                      }
                    >
                      <span
                        className="modal-link-btn"
                        style={{
                          textTransform: 'inherit',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          if (actionType === 'auth') {
                            setActionType('registration');
                            setShowPassword({ password: false, confirmPassword: false });
                            resetForm();
                          } else {
                            setActionType('auth');
                            setRestoreStep(0);
                            setShowPassword({ password: false, confirmPassword: false });
                            resetForm();
                          }
                        }}
                      >
                        {/* {translate('bonfair_contact_modal_button_send')} */}
                        {actionType === 'auth' && 'Створити обліковий запис'}
                        {(actionType === 'registration' || actionType === 'restore') &&
                          'Уже з нами?'}
                      </span>
                      {actionType === 'auth' && (
                        <span
                          className="modal-link-btn"
                          size="large"
                          style={{
                            textTransform: 'inherit',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setShowPassword({ password: false, confirmPassword: false });
                            resetForm();
                            setActionType('restore');
                          }}
                        >
                          {/* {translate('bonfair_contact_modal_button_send')} */}
                          Забули пароль?
                        </span>
                      )}
                    </Grid>
                  )}
                  {actionType === 'accBlocked' && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <StyledLabel className="authModal-title-text">
                          Доступ до акаунта заблоковано
                        </StyledLabel>
                      </Grid>
                      <Grid item xs={12} className="authModal-inputs-first">
                        <Typography
                          // component="span"

                          className="authModal-main-text"
                          style={{ marginBottom: '10px', textAlign: 'center !important' }}
                        >
                          Ваш акаунт тимчасово заблоковано через виявлені порушення політики
                          користування. Для отримання інформації та можливих дій щодо відновлення
                          акаунта, зверніться до служби підтримки.
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {actionType === 'accBlocked' && (
                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                    >
                      <LetsGoBtn3
                        id="loginBtn2FA"
                        disabled={actionType === '2FA' && values.verify.length === 0}
                        size="large"
                        onClick={() => {
                          navigate('/ua/support');
                          dispatch(setIsOpenAuthModal(false));
                        }}
                        width={257}
                        style={{ textTransform: 'inherit' }}
                        text={'До підтримки'}
                      />
                    </Grid>
                  )}
                  {actionType==='auth'&&(
                    <Grid item xs={12} className="authModal-inputs-first">
                    <Typography
                      // component="span"

                      // className="authModal-main-text"
                      sx={{ marginBottom: '10px', textAlign: 'center !important', color: '#A1A1A1', fontSize: '12px', fontFamily:'Kyiv-Sans-Regular'  }}
                    >
                      Увага: 3 спроби з неправильним паролем призведуть до блокування.
                    </Typography>
                  </Grid>
                  )}
                </Grid>
              ) : (
                <Spinner />
              )}
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    isOpenSearchBar: state.global.isOpenSearchBar
  };
};
export default connect(mapStateToProps, {
  getUserDataThunk: base.getUserDataThunk,
  getBlockedUsersThunk: base.getBlockedUsersThunk
})(AuthModal);
