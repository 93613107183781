import { Navigate, useRoutes } from 'react-router-dom';
import React from 'react';
//Pages
import MainPage from './pages/MainPage';

import MainLayout from './layouts';
import { connect, useSelector } from 'react-redux';
import SearchPage from './pages/SearchPage';
import UserPage from './pages/UserPage';
import Page404 from './pages/404';
import ServicePage from './pages/ServicePage';
import PostPage from './pages/PostPage';
import MessagesPage from './pages/MessagesPage';
import ErrorPage from './pages/errorPage';
import AccSettingsPage from './pages/AccSettingsPage';
import SchedulerPage from './pages/Scheduler';
import SupportPage from './pages/SupportPage';
import AgreementPage from './pages/Agreement';
import SavedPage from './pages/SavedPage';
import RibbonPage from './pages/RibbonPage';
import OrderPage from './pages/OrderPage';
import TariffPage from './pages/TariffPage';

// ----------------------------------------------------------------------

function Router({ language, ...props }) {
  const user = useSelector((state) => state.global.userData);
  const token = localStorage.getItem('accessToken');

  const ProtectedRoute = ({ user, children }) => {
    if ((!user && !token) || (Object.keys(user).length === 0 && !token)) {
      return <Navigate to={'/' + language} replace exact />;
    }

    return children;
  };
  return useRoutes([
    { path: '/', element: <Navigate to={'/' + language} exact /> },
    {
      path: '/:lng',
      element: <MainLayout />,
      children: [
        { path: '/:lng/', element: <MainPage /> },
        { path: '/:lng/search', element: <SearchPage /> },
        {
          path: '/:lng/service/:id',
          element: (
            <ProtectedRoute user={user} token={token}>
              <ServicePage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/order-page/:id',
          element: (
            <ProtectedRoute user={user} token={token}>
              <OrderPage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/post/:id',
          element: (
            <ProtectedRoute user={user} token={token}>
              <PostPage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/messages',
          element: (
            <ProtectedRoute user={user} token={token}>
              <MessagesPage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/scheduler',
          element: (
            <ProtectedRoute user={user} token={token}>
              <SchedulerPage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/user-page/:id',
          element: (
            <ProtectedRoute user={user} token={token}>
              <UserPage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/account-settings',
          element: (
            <ProtectedRoute user={user} token={token}>
              <AccSettingsPage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/saved',
          element: (
            <ProtectedRoute user={user} token={token}>
              <SavedPage />
            </ProtectedRoute>
          )
        },
        {
          path: '/:lng/ribbon',
          element: (
            <ProtectedRoute user={user} token={token}>
              <RibbonPage />
            </ProtectedRoute>
          )
        },

        {
          path: '/:lng/tariffs',
          element: <TariffPage />
        },
        { path: '/:lng/agreement', element: <AgreementPage /> },
        { path: '/:lng/support', element: <SupportPage /> },
        { path: '/:lng/error', element: <ErrorPage /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page404 /> },
        { path: '*', element: <Navigate to={'/' + language + '/404'} replace /> }
      ]
    }
  ]);
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(Router);
