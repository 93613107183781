import { createSlice } from '@reduxjs/toolkit';

const mainReducer = createSlice({
  name: 'mainReducer',
  initialState: {
    isOpenSearchBar: false,
    isOpenAuthModal: false,
    isOpenServiceModal: false,
    isOpenPostModal: { isOpen: false, data: null, func: null },
    isOpenUserEditModal: false,
    isOpenUserContactsModal: false,
    isOpenSupportModal: false,
    isOpenResendingModal: { isOpen: false, id: null, type: null, func: null },
    usersForResending: [],
    resendingText: '',
    isOpenUserReadersModal: false,
    isOpenImageCropModal: false,
    isFullscreenPic: false,
    isNewMessage: false,
    isNewNotification: false,
    userNotifications: [],
    isOpenDeleteModal: { isOpen: false, text: '', func: null, id: null },
    isOpenDeleteAttentionModal: false,
    isOpenCancelModal: { isOpen: false, func: null, id: null },
    userData: {},
    premiumSubscription: null,
    userSubscribers: [],
    userSubscriptions: [],
    userSavedPosts: [],
    userSavedServices: [],
    blockedUsers: [],
    usersWhoBlocked: [],
    mutedUsers: [],
    socketMsg: null,
    socketInfoMsg: null,
    stompClient: null,
    isOpenDeleteAccModal: false,
    isOpenPaymentModal: { isOpen: false, data: null, signature: null },
    isOpenChangeMailModal: false,
    isOpenDeleteAccAttentionModal: false,
    isOpenEnable2FAModal: false,
    isOpenComplainModal: { isOpen: false, func: null },
    isOpenPremiumCancelModal: false,
    isOpenPremiumModal: false,
    isOpenCardChangeModal: false
  },
  reducers: {
    setStompClient: (state, action) => {
      state.stompClient = action.payload;
    },
    setIsNewMessage: (state, action) => {
      state.isNewMessage = action.payload;
    },
    setIsNewNotification: (state, action) => {
      state.isNewNotification = action.payload;
    },
    setUserNotifications: (state, action) => {
      state.userNotifications = action.payload;
    },
    setUsersForResending: (state, action) => {
      state.usersForResending = action.payload;
    },
    setResendingText: (state, action) => {
      state.resendingText = action.payload;
    },
    setSocketMsg: (state, action) => {
      state.socketMsg = action.payload;
    },
    setSocketInfoMsg: (state, action) => {
      state.socketInfoMsg = action.payload;
    },
    setIsOpenSearchBar: (state, action) => {
      state.isOpenSearchBar = action.payload;
    },
    setIsOpenServiceModal: (state, action) => {
      state.isOpenServiceModal = action.payload;
    },
    setIsOpenPostModal: (state, action) => {
      state.isOpenPostModal = action.payload;
    },
    setIsOpenAuthModal: (state, action) => {
      state.isOpenAuthModal = action.payload;
    },
    setIsOpenUserEditModal: (state, action) => {
      state.isOpenUserEditModal = action.payload;
    },
    setIsOpenUserContactsModal: (state, action) => {
      state.isOpenUserContactsModal = action.payload;
    },
    setIsOpenSupportModal: (state, action) => {
      state.isOpenSupportModal = action.payload;
    },
    setIsOpenResendingModal: (state, action) => {
      state.isOpenResendingModal = action.payload;
    },
    setIsOpenUserReadersModal: (state, action) => {
      state.isOpenUserReadersModal = action.payload;
    },
    setIsOpenImageCropModal: (state, action) => {
      state.isOpenImageCropModal = action.payload;
    },
    setIsOpenDeleteModal: (state, action) => {
      state.isOpenDeleteModal = action.payload;
    },
    setIsOpenDeleteAttentionModal: (state, action) => {
      state.isOpenDeleteAttentionModal = action.payload;
    },
    setIsOpenCancelModal: (state, action) => {
      state.isOpenCancelModal = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserSubscribers: (state, action) => {
      state.userSubscribers = action.payload;
    },
    setUserSubscriptions: (state, action) => {
      state.userSubscriptions = action.payload;
    },
    setUserSavedPosts: (state, action) => {
      state.userSavedPosts = action.payload;
    },
    setUserSavedServices: (state, action) => {
      state.userSavedServices = action.payload;
    },
    setBlockedUsers: (state, action) => {
      state.blockedUsers = action.payload;
    },
    setUsersWhoBlocked: (state, action) => {
      state.usersWhoBlocked = action.payload;
    },
    setMutedUsers: (state, action) => {
      state.mutedUsers = action.payload;
    },
    setIsOpenFullscreenPicture: (state, action) => {
      state.isFullscreenPic = action.payload;
    },
    setIsOpenDeleteAccModal: (state, action) => {
      state.isOpenDeleteAccModal = action.payload;
    },
    setIsOpenChangeMailModal: (state, action) => {
      state.isOpenChangeMailModal = action.payload;
    },
    setIsOpenDeleteAccAttentionModal: (state, action) => {
      state.isOpenDeleteAccAttentionModal = action.payload;
    },
    setIsOpenEnable2FAModal: (state, action) => {
      state.isOpenEnable2FAModal = action.payload;
    },
    setIsOpenComplainModal: (state, action) => {
      state.isOpenComplainModal = action.payload;
    },
    setIsOpenPaymentModal: (state, action) => {
      state.isOpenPaymentModal = action.payload;
    },
    setIsOpenPremiumCancelModal: (state, action) => {
      state.isOpenPremiumCancelModal = action.payload;
    },
    setIsOpenPremiumModal: (state, action) => {
      state.isOpenPremiumModal = action.payload;
    },
    setPremiumSubscription: (state, action) => {
      state.premiumSubscription = action.payload;
    },
    setIsOpenCardChangeModal: (state, action) => {
      state.isOpenCardChangeModal = action.payload;
    }
  }
});

export const {
  setUserSavedPosts,
  setUserSavedServices,
  setResendingText,
  setUsersForResending,
  setIsOpenFullscreenPicture,
  setIsOpenSearchBar,
  setIsOpenAuthModal,
  setIsOpenPostModal,
  setIsOpenServiceModal,
  setIsOpenUserEditModal,
  setIsOpenUserContactsModal,
  setIsOpenSupportModal,
  setIsOpenResendingModal,
  setIsOpenUserReadersModal,
  setIsOpenImageCropModal,
  setIsOpenDeleteModal,
  setIsOpenCancelModal,
  setUserData,
  setUserSubscribers,
  setUserSubscriptions,
  setSocketMsg,
  setStompClient,
  setSocketInfoMsg,
  setIsNewMessage,
  setIsOpenDeleteAttentionModal,
  setBlockedUsers,
  setIsOpenDeleteAccModal,
  setIsOpenChangeMailModal,
  setIsNewNotification,
  setUserNotifications,
  setUsersWhoBlocked,
  setMutedUsers,
  setIsOpenDeleteAccAttentionModal,
  setIsOpenEnable2FAModal,
  setIsOpenComplainModal,
  setIsOpenPaymentModal,
  setIsOpenPremiumCancelModal,
  setIsOpenPremiumModal,
  setPremiumSubscription,
  setIsOpenCardChangeModal
} = mainReducer.actions;
//
export default mainReducer.reducer;
